import notFoundImg from "@/assets/img/404.png";
import { NavLink } from "react-router-dom";
import useTranslation from "@/hooks/useTranslation";

const NotFound = () => {
  const multiLanguage = useTranslation();
  return (
    <div className="w-full h-[calc(100vh_-_104px)] flex justify-center items-center flex-col gap-y-6">
      <img src={notFoundImg} className="w-[380px] h-[368px]" />
      <div className="flex flex-col gap-y-3">
        <p className="text-foreground text-[20px] leading-6 font-medium text-center">{multiLanguage("common.not_found_tips")}</p>
        <NavLink to={"/trade"}>
          <p className="text-neutral-content text-[14px] leading-5 underline cursor-pointer text-center">{multiLanguage("common.go_home")}</p>
        </NavLink>
      </div>
    </div>
  );
};

export default NotFound;
