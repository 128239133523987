export const CustomEvents = {
  "open-disclaimer-dialog": "open-disclaimer-dialog",
  "close-disclaimer-dialog": "close-disclaimer-dialog",
  "open-invite-dialog": "open-invite-dialog",
  "close-invite-dialog": "close-invite-dialog",
  "open-sign-dialog": "open-sign-dialog",
  "close-sign-dialog": "close-sign-dialog",
  "set-disclaimer-sign-dialog": "set-disclaimer-sign-dialog",
  "open-order-detail": "open-order-detail",
  "open-settings-sheet": "open-settings-sheet",
  "open-wallet-connect-dialog": "open-wallet-connect-dialog",
  "close-wallet-connect-dialog": "close-wallet-connect-dialog",
  "set-wallet-connect-dialog": "set-wallet-connect-dialog",
  "open-close-position-dialog": "open-close-position-dialog",
  "ws-private-message": "ws-private-message",
  "ws-public-message": "ws-public-message",
  "ws-message": "ws-message",
  "ws-connect-error": "ws-connect-error",
  "account-onboard": "account-onboard",
  "open-transfer-out-dialog": "open-transfer-out-dialog",
};
