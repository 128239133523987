import { useRequest } from "ahooks";
import { getUserInfo, logoutUser } from "@/apis/api/user";

export const useGetUser = () => {
  return useRequest(() => getUserInfo().then((res) => res.data), { manual: true });
};

export const useUserLogout = () => {
  return useRequest(() => logoutUser().then((res) => res.data), { manual: true });
};
