/* eslint-disable no-unsafe-optional-chaining */
// 平替 src/interfaces/main.js
import { fullChains } from "@/constants/wallets";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const defaultChainList = [
  // {
  //   chain: "Goerli - Testnet",
  //   chainId: 5,
  //   chainIconUrl: "https://l2dex-image-static.dev.apexplus.exchange/chains/chain_logos/Goerli.svg",
  //   gasToken: "ETH",
  //   webTxUrl: "https://goerli.etherscan.io/tx/",
  // },
  // {
  //   chain: "BNB Chain - Testnet",
  //   chainId: 97,
  //   chainIconUrl: "https://l2dex-image-static.dev.apexplus.exchange/chains/chain_tokens/BSC/BNB_BNB.svg",
  //   gasToken: "BNB",
  //   webTxUrl: "https://testnet.bscscan.com/tx/",
  // },
  ...fullChains?.map((i) => {
    return {
      chain: i.name,
      chainId: i.id,
      chainIconUrl: "",
      gasToken: i?.nativeCurrency?.symbol,
      webTxUrl: i?.rpcUrls?.default?.http?.[0],
      ...i,
    };
  }),
];

const unpartializeList = ["metadata"];

const initialValues = {
  reloadTrigger: 0,
  serverClock: null, // Clock 对象

  metadata: {
    coinList: [],
    global: {},
    contractList: [],
    multiChain: {
      chainList: defaultChainList,
    },
  },
};

const useInterface = create(
  persist(
    (set) => ({
      ...initialValues,
      setInterface: (newValues) => set((state) => ({ ...state, ...newValues })),
    }),
    {
      name: "interface",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => {
        const keys = Object.keys(state);
        return keys.reduce((prev, next) => {
          if (!unpartializeList.includes(next)) {
            return { ...prev, [next]: state[next] };
          }
          return prev;
        }, {});
      },
    },
  ),
);

export default useInterface;
