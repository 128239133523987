import PropTypes from "prop-types"; // Import PropTypes
import { createContext, useContext, useReducer } from "react";

// Context to provide forceReload function across the app
const ReloadContext = createContext();

const forceRenderReducer = (state) => state + 1;

export const ReloadProvider = ({ children }) => {
  const [, forceRender] = useReducer(forceRenderReducer, 0); // Use reducer to force re-renders

  return <ReloadContext.Provider value={forceRender}>{children}</ReloadContext.Provider>;
};

// Define prop types to validate children prop
ReloadProvider.propTypes = {
  children: PropTypes.node.isRequired, // Ensure children prop is passed and valid
};

// Custom hook to use the reload function
export const useReload = () => useContext(ReloadContext);
