import Spinner from "@/components/Spinner";
import React, { Suspense } from "react";
import { Navigate } from "react-router";

export default ({ routes }) => {
  const rootRoute = routes.filter((r) => r.path === "/")[0];
  rootRoute.children.push({
    path: "dashboard",
    element: <Suspense fallback={<Spinner className="flex-1" />}>{React.createElement(React.lazy(() => import("./pages/Dashboard")))}</Suspense>,
    children: [
      { index: true, element: <Navigate to="/dashboard/profile" /> },
      // {
      //   path: "positions",
      //   element: <Suspense fallback={<Spinner className="flex-1" />}>{React.createElement(React.lazy(() => import("@/modules/dashboard/components/Positions")))}</Suspense>,
      // },
      {
        path: "profile",
        element: <Suspense fallback={<Spinner className="flex-1" />}>{React.createElement(React.lazy(() => import("@/modules/dashboard/components/Profile")))}</Suspense>,
      },
      {
        path: "orders",
        element: <Suspense fallback={<Spinner className="flex-1" />}>{React.createElement(React.lazy(() => import("@/modules/dashboard/components/Orders")))}</Suspense>,
      },
      {
        path: "funds",
        element: <Suspense fallback={<Spinner className="flex-1" />}>{React.createElement(React.lazy(() => import("@/modules/dashboard/components/Funds")))}</Suspense>,
      },
      {
        path: "transfers",
        element: <Suspense fallback={<Spinner className="flex-1" />}>{React.createElement(React.lazy(() => import("@/modules/dashboard/components/Transfers")))}</Suspense>,
      },
    ],
  });
};
