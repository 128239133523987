import { createConfig, http } from "wagmi";
// import { createClient } from "viem";

// import { injected } from "wagmi/connectors";
import { walletConnect } from "wagmi/connectors";

import { createClient } from "viem";
import * as fullChainsModule from "viem/chains";

export const fullChains = Object.values(fullChainsModule);

/**
 * Updates the RPC URL for a specified chain.
 * @param {string} chainId - The id of the chain to update. eg. 1
 * @param {Array} newRpcUrls - The new RPC URLs to set.
 */
export function updateRpcUrl(chainId, newRpcUrls) {
  const chainList = [
    ...(fullChains &&
      fullChains.map((i) => {
        return {
          chain: i?.name || "",
          chainId: i?.id,
          chainIconUrl: "",
          gasToken: i?.nativeCurrency?.symbol || "",
          webTxUrl: i?.rpcUrls?.default?.http?.[0] || "",

          ...i,
        };
      })),
  ];

  const chain = chainList.find((c) => c.chainId === Number(chainId));

  if (chain) {
    chain.rpcUrls.default.http = newRpcUrls;
  }
}

export const genConfig = (chainIds = [fullChainsModule.mainnet, fullChainsModule.sepolia], needStore = false, metadataChainList = []) => {
  if (metadataChainList?.length) {
    metadataChainList.map((item) => {
      if (item?.rpcUrl) {
        updateRpcUrl(item.chainId, [item.rpcUrl]);
      }
    });
  }

  if (window?.dangerouslyExportedConfig) {
    return window.dangerouslyExportedConfig;
  }

  const config = createConfig({
    chains: chainIds,
    connectors: [
      walletConnect({
        projectId: "acbff37c5ca547d5f02cf1d0b3953ad6",
      }),
    ],
    client({ chain }) {
      return createClient({
        chain,
        transport: http(),
      });
    },
    // transports: chainIds.reduce((prev, next) => {
    //   return {
    //     ...prev,
    //     [next?.id]: http(),
    //   };
    // }, {}),
  });

  if (needStore) {
    // todo 不挂在global上
    window.dangerouslyExportedConfig = config;
  }

  return config;
};

export const disclaimerAndSignStepEnum = {
  sign: 1,
  disclaimer: 2,
  inviteCode: 3,
  unknown: 0,
};

// 测试用
export const testWalletPrivateKeys = [
  "0x1c4a8ec8fc9ea9131c4412b123a127465d9603bdbce223d2327a6980dce1f716",
  "0xed660d01744ea4829890fab0d8ce00598a793274718bc60a7a762b6c41ce444a",
  "0x8dda0b14d8da6e0bbbfde2cc850e6ce08e0ecf7fdb7f1b2a89bf5f83ce2cb8b9",
  "0xe3a1245edb84113a923c28d71c5fcae7248e6e5c64ce41cf0cef0ede2211f54d",
  "0x45a7b92944dcaa235e5ac9ac9c3669fea9acd2b3069fc88997dd432b933d07f1",
  "0xf53492a5d128d73c7152a88f79fedea5df8afb8fe6ffd1a0a3e0b603faa0b4d9",
  "0xe394e9b45dbf0f4f8b87891e7ab9a022553d8c42b6bc2efb4eac0f3c51fcf6f4",
  "0x7d9f95d855d7d08aa92b2aec13eac101cd931a93ed0f38ba087872e6c42ccaf5",
  "0x939d5d2c255ff2bf71a4951d8878d34d8bb7bdc4e7719e9a188fe08b2f0ef204",
  "0xe3035b0d79998b5195b762331f9cd91c5557ba72540b03bc5bf36eb245a74e43",
];
