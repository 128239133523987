// 平替 src/components/trade/constant/index.jsx

export const TYPE_Perpetual = ["USDC", "USDT"];
export const TYPE_triggerPrice = {
  INDEX_PRICE: "INDEX_PRICE",
  LAST_PRICE: "LAST_PRICE",
  ORACLE_PRICE: "ORACLE_PRICE",
};

export const conditionTriggerPriceTypeMap = {
  [TYPE_triggerPrice.ORACLE_PRICE]: "oraclePrice",
  [TYPE_triggerPrice.INDEX_PRICE]: "indexPrice",
  [TYPE_triggerPrice.LAST_PRICE]: "lastPrice",
};

import useTranslation from "@/utils/getTranslations";

export function getTriggerPriceTypes() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const multiLanguage = useTranslation();
  return [
    { label: multiLanguage("shortIndexPrice"), value: TYPE_triggerPrice.INDEX_PRICE },
    { label: multiLanguage("shortLastPrice"), value: TYPE_triggerPrice.LAST_PRICE },
  ];
}
export function getTriggerType(type) {
  return conditionTriggerPriceTypeMap[type];
}
export var ENUM_fundingRateType = {
  "1HRatio": "1HRatio",
  "8HRatio": "8HRatio",
  annualized: "annualized",
};
export const CONST_conditionalOrderType = ["STOP_MARKET", "TAKE_PROFIT_MARKET", "STOP_LIMIT", "TAKE_PROFIT_LIMIT"];
export const KLINE_LOCAL_KEY = "tvSetting";
// 离开多少时间后 取消订阅 单位毫秒
export const NEED_UNSUBSCRIBE_DURATION = 180000; // 3 分钟
// 下单常量
/***************************************************************************/
export const TYPE_orderSide = {
  BUY: "BUY",
  SELL: "SELL",
  // UNKNOWN_ORDER_SIDE: "UNKNOWN_ORDER_SIDE",
  // UNRECOGNIZED: "UNRECOGNIZED"
};
export const TYPE_orderType = {
  LIMIT: "LIMIT",
  MARKET: "MARKET",
  STOP_LIMIT: "STOP_LIMIT",
  STOP_MARKET: "STOP_MARKET",
  TAKE_PROFIT_LIMIT: "TAKE_PROFIT_LIMIT",
  TAKE_PROFIT_MARKET: "TAKE_PROFIT_MARKET",
  // UNKNOWN_ORDER_SIDE: "UNKNOWN_ORDER_SIDE",
  // UNRECOGNIZED: "UNRECOGNIZED"
};

export const ENUM_WITHDRAW_STATUS = {
  UNKNOWN_WITHDRAW_STATUS: "UNKNOWN_WITHDRAW_STATUS", // 未知状态
  PENDING_CENSORING: "PENDING_CENSORING", // 审查验证结果中
  SUCCESS_CENSOR_SUCCESS: "SUCCESS_CENSOR_SUCCESS", // 提现成功，钱已扣除。等待 L2 验证批准中。
  SUCCESS_L2_APPROVED: "SUCCESS_L2_APPROVED", // L2 验证批准通过【终态】
  FAILED_CENSOR_FAILURE: "FAILED_CENSOR_FAILURE", // 审查验证提现数据不正确，已撤销充值请求【终态】
  FAILED_L2_REJECT: "FAILED_L2_REJECT", // L2 拒绝，所有数据回滚。【终态】
  FAILED_L2_REJECT_APPROVED: "FAILED_L2_REJECT_APPROVED", // L2 拒绝结果验证批准通过【终态】
};

export const ENUM_TRANSFER_STATUS = {
  UNKNOWN_TRANSFER_STATUS: "UNKNOWN_TRANSFER_STATUS",
  PENDING_CHECKING: "PENDING_CHECKING", // 对方账户检查中
  PENDING_CENSORING: "PENDING_CENSORING", // 审查验证结果中
  SUCCESS_CENSOR_SUCCESS: "SUCCESS_CENSOR_SUCCESS", // 转账成功，钱已到账结算。等待 L2 验证批准中。
  SUCCESS_L2_APPROVED: "SUCCESS_L2_APPROVED", // L2 验证批准通过【终态】
  FAILED_CHECK_INVALID: "FAILED_CHECK_INVALID", // 转账失败。对方账号异常 (不存在，l2key 不匹配，被禁用等) 【终态】
  FAILED_CENSOR_FAILURE: "FAILED_CENSOR_FAILURE", // 转账失败。审查验证数据不正确。【终态】
  FAILED_L2_REJECT: "FAILED_L2_REJECT", // L2 拒绝，已到账的钱要回滚
  FAILED_L2_REJECT_APPROVED: "FAILED_L2_REJECT_APPROVED", // L2 拒绝结果验证批准通过【终态】
};

export const intervalMapping = {
  1: "MINUTE_1",
  5: "MINUTE_5",
  15: "MINUTE_15",
  30: "MINUTE_30",
  60: "HOUR_1",
  120: "HOUR_2",
  240: "HOUR_4",
  360: "HOUR_6",
  720: "HOUR_12",
  D: "DAY_1",
  W: "WEEK_1",
  M: "MONTH_1",
};

export const TVIntervalMapping = {
  1: "1",
  5: "5",
  15: "15",
  30: "30",
  60: "60",
  120: "120",
  240: "240",
  360: "360",
  720: "720",
  "1D": "D",
  "1W": "W",
  "1M": "M",
};

// WS topic
/***************************************************************************/
export const WS_TOPIC_kline = (contractId, interval, priceType) => {
  // interval ["1", "5", "15", "30", "60", "240", "1D", "1W", "1M"];
  const mappedInterval = intervalMapping[interval];
  // priceType: LAST_PRICE | ORACLE_PRICE | INDEX_PRICE
  return contractId ? `kline.${priceType}.${contractId}.${mappedInterval}` : "";
};
export const WS_TOPIC_TICKER_ALL = () => {
  return `ticker.all`;
};
export const WS_TOPIC_TICKER = (contractId) => {
  return contractId ? `ticker.${contractId}` : "";
};
export const WS_TOPIC_METADATA = () => {
  return "metadata";
};
export const WS_TOPIC_depth = (contractId) => {
  return contractId ? `depth.${contractId}.200` : "";
};
export const WS_TOPIC_recentTrades = (contractId) => {
  return contractId ? `trades.${contractId}` : "";
};
export const WS_TOPIC_FUNDING_RATE_ALL = () => {
  return "fundingRate.all";
};
export const WS_TOPIC_FUNDING_RATE = (contractId) => {
  return `fundingRate.${contractId}`;
};
export const DEFAULT_ZERO_TXT = "0.0000";
export const DEFAULT_TXT = "-";
export const TRADING_VIEW_LOCALE_MAP = {
  "en-US": "en",
  "zh-CN": "zh",
  "ja-JP": "ja",
  "ko-KR": "ko",
  "ru-RU": "ru",
  "vi-VN": "vi",
  "zh-TW": "zh_TW",
  "es-ES": "es",
  "tr-TR": "tr",
  "pt-BR": "pt",
  "fr-FR": "fr",
  "th-TH": "th",
  "id-ID": "id_ID",
  "uk-UA": "en",
  "kk-KZ": "en",
  "hi-IN": "en",
  "fil-PH": "en",
};
