import { TYPE_orderSide } from "@/constants/trade";
import getTranslations from "@/utils/getTranslations";
import { toPrecisionString, toThousandString } from "@/utils/funcs";

export default ({ toast, order, contract }) => {
  const t = getTranslations();

  toast.show({
    variant: order?.status === "CANCELED" ? "orderFailure" : "orderSuccess",
    txt: (
      <div className="flex flex-col gap-3">
        <div>
          <span className="font-semibold text-base leading-5 text-foreground">{contract.symbol}</span>·
          <span className={`font-semibold text-base ${order?.side == TYPE_orderSide.BUY ? " text-[--long]" : " text-[--short]"}`}>
            {t(order?.side)}·{toPrecisionString(order?.maxLeverage, 0, "withMinVal")}x
          </span>
        </div>
        <div className="flex">
          <div className="basis-1/2">
            <div className="text-foreground-tertiary">{t("orderPrice")}</div>
            <div className="text-foreground">
              {order?.type?.includes("LIMIT") ? toThousandString(order?.price, contract.pricePrecision) : t("marketPrice")} {contract.quoteCoin}
            </div>
          </div>
          <div className="basis-1/2">
            <div className="text-foreground-tertiary">{t("qty")}</div>
            <div className="text-foreground">
              {toPrecisionString(order?.size, contract.sizePrecision)} {contract.baseCoin}
            </div>
          </div>
        </div>
        <div className="text-success">{t(order?.status)}</div>
      </div>
    ),
  });
};
