export default [
  {
    lang: "en-US",
    name: "English",
  },
  {
    lang: "zh-CN",
    name: "简体中文",
  },
  {
    lang: "zh-TW",
    name: "繁體中文",
  },
  {
    lang: "ko-KR",
    name: "한국어",
  },
  {
    lang: "ja-JP",
    name: "日本語",
  },
  {
    lang: "es-ES",
    name: "Español",
  },
  {
    lang: "tr-TR",
    name: "Türkçe",
  },
  {
    lang: "de-DE",
    name: "Deutsch",
  },
  {
    lang: "fr-FR",
    name: "Français",
  },
  {
    lang: "pt-PT",
    name: "Protuguês",
  },
  {
    lang: "fil-PH",
    name: "Tagalog",
  },
  {
    lang: "hi-IN",
    name: "हिंदी",
  },
  {
    lang: "th-TH",
    name: "ไทย",
  },
  {
    lang: "vi-VN",
    name: "tiếng việt",
  },
  {
    lang: "id-ID",
    name: "bahasa Indonesia",
  },
  {
    lang: "kk-KZ",
    name: "қазақ тілі",
  },
  {
    lang: "ru-RU",
    name: "Русский",
  },
  {
    lang: "uk-UA",
    name: "українська",
  },
];
