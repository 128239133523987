import useIsFromAppRedirect from "@/hooks/useIsFromAppRedirect";
import HomeFooter from "@/modules/home/components/HomeFooter";
import HomeHeader from "@/modules/home/components/HomeHeader";
import { Outlet } from "react-router";

export default function Home() {
  const isApp = useIsFromAppRedirect();

  return (
    <div className="bg-[--module] relative">
      {!isApp && <HomeHeader />}
      <Outlet />
      {!isApp && <HomeFooter />}
    </div>
  );
}
