import React from "react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

function HeaderNavLink({ className, children, ...rest }) {
  const commonClass = "w-auto h-full flex items-center justify-center px-4 nav-link";
  return (
    <NavLink
      className={({ isActive, isPending }) => twMerge(isPending ? "loading loading-xs inline-flex " : isActive ? "text-primary fill-primary active-nav-link " + commonClass : commonClass, className)}
      {...rest}
    >
      {children}
    </NavLink>
  );
}
HeaderNavLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default HeaderNavLink;
