import Spinner from "@/components/Spinner";
import { useToast } from "@/components/ui/use-toast";
import ui from "@/constants/ui";
import useActiveChains from "@/hooks/useActiveChains";
import useTranslation from "@/hooks/useTranslation";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { useSwitchChain } from "wagmi";
import HeaderDropDown from "./HeaderDropDown";

const ChainDropDownItem = ({ selectedChainId, config, handleSwitchChain }) => {
  // const { currentActiveChain } = useActiveChains();
  // const ifActive = useMemo(() => currentActiveChain?.chainId === config?.chainId, [config?.chainId, currentActiveChain?.chainId]);
  return (
    <a onClick={() => handleSwitchChain(config)} className={twMerge(`inline-flex items-center gap-2 px-4`, selectedChainId == config.chainId ? "selected" : "", ui?.headerDropDownIconItemClassName)}>
      <img className="icon size-6" src={config?.chainIconUrl} />
      <div className="font-medium text-ellipsis overflow-hidden whitespace-nowrap">{config?.chain}</div>
    </a>
  );
};

ChainDropDownItem.propTypes = {
  config: PropTypes.any,
  selectedChainId: PropTypes.any,
  handleSwitchChain: PropTypes.func,
};

const ChainDropDown = ({ iconClassName, ...props }) => {
  const { chains, currentActiveChain } = useActiveChains();
  const { switchChainAsync, isPending } = useSwitchChain();
  const multiLanguage = useTranslation();
  const { toast } = useToast();

  const handleSwitchChain = async (config) => {
    if (+currentActiveChain?.chainId === +config?.chainId) return;
    try {
      await switchChainAsync({ chainId: +config?.chainId });
    } catch (e) {
      toast.show(multiLanguage(typeof e?.details == "string" ? e?.details : e.message), { type: "error" });
    }
  };

  if (!chains?.length) return null;

  return (
    <HeaderDropDown
      title={
        <div className="flex flex-row items-center gap-2">
          {isPending ? <Spinner className={twMerge("spinner size-4", iconClassName)} /> : <img className={twMerge("icon size-4", iconClassName)} src={currentActiveChain?.chainIconUrl} />}
          <span>{currentActiveChain?.chain}</span>
        </div>
      }
      showCaret={true}
      titleClassName={ui?.headerDropDownIconTitleClassName}
      contentClassName="min-w-[180px]"
      {...props}
    >
      {chains?.map((i) => {
        return <ChainDropDownItem selectedChainId={currentActiveChain?.chainId} key={i?.chainId} handleSwitchChain={handleSwitchChain} config={i} />;
      })}
    </HeaderDropDown>
  );
};

ChainDropDown.propTypes = {
  iconClassName: PropTypes.string,
};
export default ChainDropDown;
