import { useReload } from "@/providers/ReloadProvider";
import realtimePublic from "@/services/ws.realtimePublic";
import useInterface from "@/stores/interface";
import { useEffect } from "react";

/**
 * 监听 public topic，调用 onSubscribe 回调
 */
const useSubscribePublicTopic = (onSubscribe, options = {}) => {
  const topic = options.topic;
  const group = options.group; // 订阅分组，同一组内只能监听一个 topic
  const reSubscribe = options.reSubscribe; // reSubscribe 值变化，重新订阅
  const reloadApp = useReload(); // Get the reload function
  const { setInterface, reloadTrigger } = useInterface();

  useEffect(() => {
    let groupTopic = realtimePublic.groupTopic || {};
    if (topic) {
      if (group && groupTopic[group] == topic && !reSubscribe) return; // 防止重复取消订阅再订阅
      if ((group && groupTopic[group] && groupTopic[group] != topic) || reSubscribe) {
        realtimePublic.unsubscribe(groupTopic[group], onSubscribe, true);
        group && (realtimePublic.groupTopic = { ...groupTopic, [group]: false });
      }
      realtimePublic.ready(() => {
        group && (realtimePublic.groupTopic = { ...groupTopic, [group]: topic });
        realtimePublic.subscribe(topic, onSubscribe);
      });
    }
    return () => {
      if (topic) {
        let groupTopic = realtimePublic.groupTopic || {};
        group && (realtimePublic.groupTopic = { ...groupTopic, [group]: false });
        realtimePublic.unsubscribe(topic, onSubscribe, false);
        reloadApp(); // Ensure reload happens only after unsubscribe completes
        setInterface({ reloadTrigger: reloadTrigger + 1 });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic, group, reSubscribe]);
};
export default useSubscribePublicTopic;
