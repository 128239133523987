import Icon from "@/components/Icon";
import { URLKey } from "@/constants/urls";
import useTranslation from "@/hooks/useTranslation";
import BookOpenIcon from "@/icons/book-open.svg";
import DiscordIcon from "@/icons/discord.svg";
import NoteIcon from "@/icons/note.svg";
import TelegramIcon from "@/icons/telegram.svg";
import TwitterIcon from "@/icons/twitter.svg";
import HeaderDropDown from "@/modules/layout/components/HeaderDropDown";
import { Link } from "react-router-dom";

export default function More() {
  const t = useTranslation();
  const moreList = [
    {
      label: t("home.terms"),
      icon: NoteIcon,
      url: "/terms",
    },
    {
      label: t("common.helpDocument"),
      icon: BookOpenIcon,
      url: URLKey.GITBOOK_DOCS,
    },
    {
      label: t("social.twitter"),
      icon: TwitterIcon,
      url: URLKey.SOCIAL_X,
    },
    {
      label: t("social.Discord"),
      icon: DiscordIcon,
      url: URLKey.SOCIAL_DISCORD,
    },
    {
      label: t("social.telegram"),
      icon: TelegramIcon,
      url: URLKey.SOCIAL_TELEGRAM,
    },
  ];
  return (
    <HeaderDropDown
      titleClassName="nav-link hover:bg-transparent px-4"
      className="border-none hover:bg-neutral hover:rounded-full [&>summary]:open:bg-neutral rounded-full"
      title={t("common.more")}
      showCaret={true}
    >
      {moreList.map((item, index) => (
        <Link key={`more-item-${index}`} to={item.url} className="flex flex-row gap-[8px]" target="_blank" rel="noreferrer">
          <Icon icon={item.icon} className="w-5 h-5" />
          <div>{item.label}</div>
        </Link>
      ))}
    </HeaderDropDown>
  );
}
