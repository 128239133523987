import Icon from "@/components/Icon";
import Spinner from "@/components/Spinner";
import { useToast } from "@/components/ui/use-toast";
import ui from "@/constants/ui";
import useActiveChains from "@/hooks/useActiveChains";
import useTranslation from "@/hooks/useTranslation";
import accountIcon from "@/icons/account.svg";
import copyIcon from "@/icons/copy.svg";
import disconnectIcon from "@/icons/disconnect.svg";
import unsupportedIcon from "@/icons/unsupported.svg";
import walletIcon from "@/icons/wallet.svg";

import HeaderDropDown from "@/modules/layout/components/HeaderDropDown";
import useGlobal from "@/stores/global";
import { shortStr } from "@/utils/funcs";
import CopyToClipboard from "react-copy-to-clipboard";

// import ApiManagementIcon from "@/icons/api-management.svg";
import { useUserLogout } from "@/apis/query/user";
import AccountSwitch from "@/modules/layout/components/AccountSwitch";
import useUser from "@/stores/user";
import { Suspense, lazy } from "react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useAccount, useDisconnect, useSwitchChain } from "wagmi";

const Settings = lazy(() => import("./Settings"));

const WalletConnect = () => {
  const { disconnect } = useDisconnect();
  const { runAsync: userLogout } = useUserLogout();
  const { hasDisplayConnectWalletTour, setGlobal } = useGlobal();
  const { toast: Toast } = useToast();
  const multiLanguage = useTranslation();
  const account = useAccount();
  const { switchChainAsync, isPending } = useSwitchChain();
  const { chains } = useActiveChains();
  const { unsupported } = useActiveChains();

  const { isAlreadySigned } = useUser();
  const alreadySigned = isAlreadySigned();
  const handleSwitchChainId = async () => {
    try {
      await switchChainAsync({ chainId: +chains?.[0]?.chainId });
    } catch (e) {
      Toast.show(multiLanguage(typeof e?.details == "string" ? e?.details : e.message), { type: "error" });
    }
  };

  const switchChainIfNeeded = () => {
    if (account?.address && unsupported) {
      handleSwitchChainId();
    }
  };

  // eslint-disable-next-line react/prop-types
  const ConnectWalletButton = ({ onClick }) => (
    <button
      className={twMerge("btn btn-primary", ui?.connectWalletBtnClassName)}
      onClick={() => {
        setGlobal({ connectModalVisible: true });
        onClick?.();
      }}
    >
      <div className="text-sm font-medium text-inverse-primary">{multiLanguage("connectWallet")}</div>
    </button>
  );

  return account?.address ? (
    <>
      <HeaderDropDown
        id="wallet-connect-dropdown"
        onClick={switchChainIfNeeded}
        titleClassName={twMerge("hover:fill-primary hover:bg-[--highlight] ", ui?.headerDropDownIconTitleClassName)}
        contentClassName="min-w-[190px]"
        className="dropdown-end"
        title={
          <div className="flex flex-row items-center gap-2">
            {isPending ? (
              <Spinner className="size-5" />
            ) : unsupported ? (
              <Icon icon={unsupportedIcon} className="size-5" />
            ) : account?.connector?.icon ? (
              <img className="size-5" src={account?.connector?.icon} />
            ) : (
              <Icon icon={walletIcon} className="size-5" />
            )}
            <span className="text-sm font-bold ">{shortStr(account?.address, 10)}</span>
          </div>
        }
        showCaret={account?.address && !unsupported}
      >
        {account?.address && !unsupported && !alreadySigned && (
          <div className={twMerge("flex flex-col gap-3 justify-center text-xs my-2 text-center", ui?.recoverClassName)}>
            <div className="">{multiLanguage("welcomeBack")}</div>
            <button
              className="btn btn-primary btn-xs"
              onClick={() => {
                setGlobal({ disclaimerAndSignVisible: true });
              }}
            >
              {multiLanguage("recoverKeys")}
            </button>
          </div>
        )}
        {account?.address && !unsupported && alreadySigned && <AccountSwitch />}
        {account?.address && !unsupported && alreadySigned && (
          <NavLink to="/account" className="flex items-center gap-2">
            <Icon icon={accountIcon} className="icon size-5" />
            {multiLanguage("subAccounts")}
          </NavLink>
        )}
        {account?.address && !unsupported && alreadySigned && (
          <Suspense>
            <Settings></Settings>
          </Suspense>
        )}
        {account?.address && !unsupported && (
          <CopyToClipboard
            onCopy={() => {
              Toast.show(multiLanguage("copySuccess"));
            }}
            text={account?.address}
          >
            <a className={twMerge(`inline-flex items-center gap-2 px-4 cursor-pointer`, ui?.headerDropDownIconItemClassName)}>
              <Icon icon={copyIcon} className="icon size-6" />
              <span>{multiLanguage("menu.copy")}</span>
            </a>
          </CopyToClipboard>
        )}

        {/* TODO：暂时先下掉 API 管理 */}
        {/* {account?.address && !unsupported && alreadySigned && (
          <NavLink to="/keyManagement">
            <div className="flex items-center gap-2">
              <Icon icon={ApiManagementIcon} className="icon size-5" />
              <span>{multiLanguage("api.api_manage")}</span>
            </div>
          </NavLink>
        )} */}
        {account?.address && !unsupported && (
          <a
            onClick={async () => {
              userLogout().finally(() => {
                disconnect();
              });
            }}
            className={twMerge(`inline-flex items-center gap-2 px-4`, ui?.headerDropDownIconItemClassName)}
          >
            <Icon icon={disconnectIcon} className="icon size-6" />
            <span>{multiLanguage("menu.disconnect")}</span>
          </a>
        )}
      </HeaderDropDown>
    </>
  ) : !hasDisplayConnectWalletTour ? (
    <div className="">
      <div className="absolute z-20 inline-flex px-2 py-1 border-2 border-dashed border-brand rounded">
        <ConnectWalletButton
          onClick={() => {
            setGlobal({ hasDisplayConnectWalletTour: true });
          }}
        />

        <div className="absolute top-[68px] -right-0.5 min-w-80 flex flex-col p-4 bg-bg-secondary rounded-[10px] drop-shadow-border">
          <div className="absolute bottom-full right-8 h-0 w-0 border-x-[8px] border-x-transparent border-b-[8px] border-b-bg-secondary" />
          <div className="text-base text-foreground font-medium">{multiLanguage("connectWallet")}</div>

          <div className="mt-2 text-sm text-foreground-secondary">{multiLanguage("guide.connectAndVerification")}</div>

          <div className="flex flex-row gap-2 items-center justify-end">
            <button
              className={twMerge("btn btn-accent mt-4 self-end", ui?.connectWalletBtnClassName)}
              onClick={() => {
                setGlobal({ hasDisplayConnectWalletTour: true });
              }}
            >
              <div className="text-sm font-medium text-white">{multiLanguage("guide.closeBtn")}</div>
            </button>
            <button
              className={twMerge("btn btn-primary mt-4 self-end", ui?.connectWalletBtnClassName)}
              onClick={() => {
                setGlobal({ connectModalVisible: true, hasDisplayConnectWalletTour: true });
              }}
            >
              <div className="text-sm font-medium text-inverse-primary">{multiLanguage("guide.connect")}</div>
            </button>
          </div>
        </div>
      </div>

      <div className="fixed z-10 inset-0 bg-black/30"></div>
      <div className="inline-flex px-2 py-1 border-2 border-transparent">
        <ConnectWalletButton />
      </div>
    </div>
  ) : (
    <ConnectWalletButton />
  );
};
export default WalletConnect;
