import Icon from "@/components/Icon";
import Spinner from "@/components/Spinner";
import Trans from "@/components/Trans";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { CustomEvents } from "@/constants/customEvents";
import useTranslation from "@/hooks/useTranslation";
import error from "@/icons/error.svg";
import success from "@/icons/success.svg";
import walletIcon from "@/icons/wallet.svg";
import useGlobal from "@/stores/global";
import { useBoolean, useEventListener } from "ahooks";
import { useRef, useState } from "react";
import { useConnectors } from "wagmi";

import metamask from "@/assets/img/walllet/metamask.png";
import walletconnect from "@/assets/img/walllet/walletconnect.png";
import bitget from "@/assets/img/walllet/bitget.png";
import { twMerge } from "tailwind-merge";

const staticWallets = [
  {
    id: "io.metamask",
    name: "MetaMask",
    icon: metamask,
    link: "https://metamask.io/",
  },
  {
    id: "com.bitget.web3",
    name: "Bitget Wallet",
    icon: bitget,
    link: "https://web3.bitget.com/",
  },
  {
    id: "walletConnect",
    name: "WalletConnect",
    icon: walletconnect,
    link: "https://walletconnect.com/",
  },
];

const WalletConnectCmp = () => {
  const [isPending, { setTrue, setFalse }] = useBoolean(false);
  const [connectStatus, setConnectStatus] = useState(undefined);
  const t = useTranslation();
  const { toast: Toast } = useToast();
  const { setGlobal } = useGlobal();
  const connectorConfig = useRef();

  const connectors = useConnectors();

  const mergedConnectors = staticWallets.map((w) => connectors.find((c) => c.id === w.id) || w).concat(connectors.filter((w) => !staticWallets.find((s) => s.id === w.id)));

  // 优先用本地 icon metamask 特殊处理
  mergedConnectors.forEach((w) => {
    const staticWalletIcon = staticWallets.find((s) => s.id === w.id)?.icon;
    if (staticWalletIcon && w.id != "io.metamask") {
      w.icon = staticWalletIcon;
    }
  });

  const handleConnect = async (config) => {
    try {
      if (!connectors.find((w) => w.id === config.id)) {
        setGlobal?.({ connectModalVisible: false, walletInstallGuide: config });
        return;
      }
      setConnectStatus(undefined);
      setTrue();
      connectorConfig.current = config;
      await config?.connect();
      setGlobal?.({ connectModalVisible: false });
      setFalse();
      setConnectStatus(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      Toast.show(t(typeof e?.details == "string" ? e?.details : e.message), { type: "error" });
      setFalse();
      setConnectStatus(false);
    }
  };

  const handleRetryConnect = () => {
    if (connectorConfig?.current) {
      handleConnect(connectorConfig.current);
    }
  };

  if (connectStatus === false) {
    return (
      <div className="flex flex-col w-full items-center">
        <Icon icon={error} className="size-14 mt-6" />
        <div className="text-foreground text-sm font-medium mt-4">
          <Trans i18nKey="failToConnect">Failed to connect</Trans>
        </div>

        <button onClick={handleRetryConnect} className="btn btn-neutral h-12 min-h-12 min-w-[234px] mt-8 cursor-pointer text-foreground text-base font-semibold">
          <Trans i18nKey="retry">Retry</Trans>
        </button>
      </div>
    );
  }

  if (connectStatus === true) {
    return (
      <div className="w-full flex flex-col gap-4 items-center">
        <Icon icon={success} className="size-16" />
        <div className="text-foreground text-base font-bold">
          <Trans i18nKey="connected">Connected</Trans>
        </div>
      </div>
    );
  }

  if (isPending) {
    return (
      <div className="w-full flex flex-col gap-8 items-center">
        <Spinner iconClassName="size-14" className="text-primary fill-primary mt-6"></Spinner>
        <div className="flex flex-col gap-4 items-center">
          <div className="text-foreground text-sm font-medium mt-4">
            <Trans i18nKey="requestingConnection">Requesting Connection</Trans>
          </div>
          {/* <div className="text-base-300 text-sm">
            <Trans i18nKey="connectingDesc">Open the MetaMask browser extension to connect your wallet.</Trans>
          </div> */}
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="mt-5 text-sm">
        <Trans i18nKey="chooseWallet">Select a wallet</Trans>
      </div>
      <ul className="grid grid-wrap grid-cols-2 items-center gap-4 mt-4">
        {mergedConnectors?.map((i) => {
          return (
            <li
              onClick={() => handleConnect(i)}
              key={i?.id}
              className="cursor-pointer rounded-lg p-4 bg-bg-secondary text-foreground flex flex-col justify-center items-center gap-3 hover:bg-primary hover:text-bg-primary transition-all"
            >
              <div className="size-12 rounded-full bg-bg-tertiary inline-flex items-center justify-center">
                {i?.icon ? <img src={i?.icon} className={twMerge("rounded-full", i.id == "io.metamask" ? "size-9" : "size-12")} /> : <Icon icon={walletIcon} className="size-12" />}
              </div>
              <div className="text-sm font-medium">{i.name}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const WalletConnectDialog = () => {
  const t = useTranslation();
  const { connectModalVisible, walletInstallGuide, setGlobal } = useGlobal();

  const handleWalletChange = (v) => {
    setGlobal?.({ walletInstallGuide: v ? walletInstallGuide : null });
  };

  const handleChanges = (v) => {
    setGlobal?.({ connectModalVisible: v });
  };

  // 添加事件触发
  useEventListener(CustomEvents["open-wallet-connect-dialog"], () => {
    setGlobal({ connectModalVisible: true });
  });

  useEventListener(CustomEvents["close-wallet-connect-dialog"], () => {
    setGlobal({ connectModalVisible: false });
  });

  useEventListener(CustomEvents["set-wallet-connect-dialog"], (e) => {
    setGlobal(e?.detail);
  });

  return (
    <>
      <Dialog open={connectModalVisible} onOpenChange={handleChanges}>
        <DialogContent
          className="max-w-[480px]"
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          <DialogHeader>
            <DialogTitle>{t("connectWallet")}</DialogTitle>
          </DialogHeader>

          <WalletConnectCmp />
        </DialogContent>
      </Dialog>
      <Dialog open={!!walletInstallGuide} onOpenChange={handleWalletChange}>
        <DialogContent
          className="max-w-[386px]"
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          <DialogHeader>
            <DialogTitle>{t("connectWallet")}</DialogTitle>
          </DialogHeader>
          <div className=" flex flex-col gap-4 [&>div]:w-full mt-4">
            <div className="p-4 flex items-center justify-center">
              <div className="size-12 rounded-full bg-bg-tertiary inline-flex items-center justify-center">
                {walletInstallGuide?.icon ? (
                  <img src={walletInstallGuide?.icon} className={twMerge("rounded-full", walletInstallGuide.id == "io.metamask" ? "size-9" : "size-12")} />
                ) : (
                  <Icon icon={walletIcon} className="size-12" />
                )}
              </div>
            </div>
            <div className="text-center">{t("installWalletGuide")}</div>
            <button
              className="btn btn-accent h-12 min-h-12 mt-4"
              onClick={() => {
                setGlobal?.({ connectModalVisible: true, walletInstallGuide: null });
              }}
            >
              {t("changeWallet")}
            </button>
            <button
              className="btn btn-primary h-12 min-h-12"
              onClick={() => {
                window.open(walletInstallGuide?.link);
              }}
            >
              {t("installWallet", { wallet: walletInstallGuide?.name })}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

WalletConnectDialog.propTypes = {};

export default WalletConnectDialog;
