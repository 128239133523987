import { useIntl } from "react-intl";

/**
 * 由于 多语言的 values 占位符是 {{}} 原生 formatMessage 不支持，需要单独处理
 * @usage t("ACCOUNT_NOT_FOUND", { accountId: "abc" });
 */
export default () => {
  const { formatMessage } = useIntl();
  return (id, values = {}) => {
    let result = "";
    result = id && formatMessage({ id }, values)?.replace(/\{\{(.*?)\}\}/gi, (m, k) => values[k] || "");
    return result;
  };
};
