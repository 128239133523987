export const ENV_KEY = {
  local: "DEV",
  dev: "DEV",
  testnet_internal: "Testnet - Internal",
  testnet_public: "Testnet",
  mainnet: "Mainnet",
};

export const ENV_URL = {
  dev: "http://thunderbird-web.test1.bitget.tools/",
  testnet_internal: "https://thunderbird-web-testnet-internal.sniper5.vip/",
  testnet_public: "https://testnet.tetadex.com/",
  mainnet: "https://tetadex.com/",
};
