import Icon from "@/components/Icon";
import Trans from "@/components/Trans";
import { ENV_KEY, ENV_URL } from "@/constants/env";
import useTranslation from "@/hooks/useTranslation";
import logo from "@/icons/logo.svg";
import AppQrCode from "@/modules/layout/components/AppQrCode";
import HeaderDropDown from "@/modules/layout/components/HeaderDropDown";
import HeaderNavLink from "@/modules/layout/components/HeaderNavLink";
import LangSwitch from "@/modules/layout/components/LangSwitch";
import More from "@/modules/layout/components/More";
import NotificationCenter from "@/modules/layout/components/NotificationCenter";
import WalletConnect from "@/modules/layout/components/WalletConnect";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import ChainDropDown from "./ChainDropDown";

function Header({ className }) {
  const t = useTranslation();

  const curEnv = ENV_KEY[process.env.REACT_APP_ENV];
  const dev = [
    {
      label: "Testnet - Internal",
      value: "testnet_internal",
      href: `${ENV_URL["testnet_internal"]}`,
    },
    {
      label: "Dev",
      value: "dev",
      href: `${ENV_URL["dev"]}`,
    },
  ];

  const env = [
    {
      label: "Mainnet",
      value: "mainnet",
      href: `${ENV_URL["mainnet"]}`,
    },
    {
      label: "Testnet",
      value: "testnet",
      href: `${ENV_URL["testnet_public"]}`,
    },
    ...(curEnv === ENV_KEY["dev"] || curEnv === ENV_KEY["testnet_internal"] ? dev : []),
  ];

  return (
    <div className={twMerge("h-16 flex module px-10 items-center !rounded-t-none !rounded-b-2xl", className)}>
      {/* tetadex logo */}
      <NavLink to="/" className="inline-flex items-center h-full">
        <Icon icon={logo} className="h-7" />
      </NavLink>

      {/* dashboard */}
      <HeaderNavLink className={"ml-6"} to="/dashboard">
        {t("menu.Dashboard")}
      </HeaderNavLink>

      {/* trade */}
      <HeaderNavLink to="/trade">{t("menu.trade")}</HeaderNavLink>

      {/* market */}
      <HeaderNavLink to="/markets">{t("contracts")}</HeaderNavLink>

      {/* faucet */}
      {process.env.REACT_APP_FAUCET == "1" && (
        <HeaderNavLink to="/faucet">
          <Trans key={"Faucet"}>Faucet</Trans>
        </HeaderNavLink>
      )}

      {/* more */}
      <More />

      {/* Right Nav Buttons */}
      <div className="ml-auto h-full inline-flex items-center gap-2">
        {/* Lang Switch */}
        <LangSwitch />
        {/* Notification */}
        <NotificationCenter />
        {/* App Qr Code */}
        {process.env.REACT_APP_QR_LOGIN == "1" && <AppQrCode />}

        {/* Environment Switch */}
        <HeaderDropDown className="ml-1" title={curEnv} showCaret={true}>
          {env.map((e) => (
            <a
              key={e.label}
              href={e.href}
              target="_blank"
              rel="noreferrer"
              className={e.label == curEnv ? "selected" : ""}
              onClick={(event) => {
                if (!e.href) {
                  event.preventDefault();
                }
              }}
            >
              {e.label}
            </a>
          ))}
        </HeaderDropDown>

        {/* Chain Switch */}
        <ChainDropDown />

        {/* Connect Wallet */}
        <WalletConnect />
      </div>
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
