/**
 * 监听全局接口错误，弹出错误提示
 */
import React, { useEffect } from "react";
import request from "axios";
import { useToast } from "@/components/ui/use-toast";
import useTranslation from "@/hooks/useTranslation";

const ResponseErrorToast = () => {
  const { toast: Toast } = useToast();
  const t = useTranslation();
  useEffect(() => {
    const handler = request.interceptors.response.use(
      (res) => res,
      (err) => {
        // const status = err?.response?.status || 200;
        // const url = err?.config?.url || "";
        // const path = url.replace(request.defaults.baseURL, "").replace(/\?.+$/g, "").replace(/^\//, "");
        // const method = err?.config?.method || "get";

        const interceptError = typeof err?.config?.interceptError == "undefined" ? true : err?.config?.interceptError;
        if (interceptError === false) return Promise.reject(err);
        if (typeof interceptError === "function" && interceptError(err) !== true) return Promise.reject(err);

        let toastMessage = err?.msg || err?.message;
        if (toastMessage && err?.code) {
          toastMessage = t(err?.code, err?.errorParam);
        }
        toastMessage && err?.config?.errorToastType == "default"
          ? Toast.show({
              type: "error",
              title: err?.config?.errorToastTitle || "",
              txt: toastMessage,
            })
          : Toast.show(toastMessage, { type: "error" });

        return Promise.reject(err);
      },
    );
    return () => {
      request.interceptors.response.eject(handler);
    };
  });
  return <></>;
};

export default ResponseErrorToast;
