import Spinner from "@/components/Spinner";
import React, { Suspense } from "react";

export default ({ routes }) => {
  const rootRoute = routes.filter((r) => r.path === "/")[0];
  rootRoute.children.push({
    path: "funding/:symbol",
    element: <Suspense fallback={<Spinner className="min-h-[90vh]" />}>{React.createElement(React.lazy(() => import("./pages/Funding")))}</Suspense>,
  });
};
