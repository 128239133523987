import Spinner from "@/components/Spinner";
import React, { Suspense } from "react";

export default ({ routes }) => {
  const rootRoute = routes.filter((r) => r.path === "/" && r.tag == "home")[0];
  rootRoute.children.push({
    path: "protocol",
    element: <Suspense fallback={<Spinner className="min-h-[90vh]" />}>{React.createElement(React.lazy(() => import("./pages/Protocol")))}</Suspense>,
  });
};
