import axios from "@/services/request";

export const withdrawApi = {
  getNormalWithdrawableAmount: "/v1/private/assets/getNormalWithdrawableAmount",
};

/**
 * Retrieves the normal withdrawable amount for current user.
 * @param {Object} requestParam
 * @param {Object} requestParam.params
 * @param {String} requestParam.params.address User's wallet address.
 * @returns {Promise} Returns a Promise that resolves to an object containing the response data, or is rejected with an error.
 */
export const getNormalWithdrawableAmountApi = (requestParam) => {
  return axios.get(withdrawApi.getNormalWithdrawableAmount, { ...requestParam, interceptError: false });
};
