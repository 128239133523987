import ResponseErrorToast from "@/components/ResponseErrorToast";
import { TooltipProvider } from "@/components/ui/tooltip";
import { fullChains, genConfig } from "@/constants/wallets";
import Layout from "@/layout";
import HomeLayout from "@/modules/home/pages/Layout";
import { ReloadProvider } from "@/providers/ReloadProvider";
import "@/services/request";
import useGlobal from "@/stores/global";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { IntlProvider } from "react-intl";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { WagmiProvider } from "wagmi";
import en from "./locales/en-US.json";
import NotFound from "./modules/layout/components/404";
import useInterface from "./stores/interface";

const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "*",
        element: (
          <div className="flex-1 flex items-center justify-center text-lg">
            <NotFound />
          </div>
        ),
      },
    ],
  },
  {
    path: "/",
    element: <HomeLayout />,
    children: [],
    tag: "home",
  },
];
// load all modules
const modules = require.context("@/modules", true, /^\.\/[\w-]+\/index\.jsx$/);
modules.keys().forEach((filePath) => {
  modules(filePath).default({ routes });
});

// load all i18n messages

const allMessages = require.context("@/locales", true, /^\.\/[\w]+-[\w]+.json$/);
const allMessagesMap = {};
allMessages.keys().forEach((filePath) => {
  const module = allMessages(filePath);
  const moduleName = filePath.match(/.+\/([\w]+-.+)\.json$/)?.[1];
  moduleName && (allMessagesMap[moduleName] = module);
});

const queryClient = new QueryClient();

function App() {
  const messages = { ...Object.fromEntries(Object.entries(allMessagesMap).map(([lang, messages]) => [lang, { ...en, ...messages }])), "en-US": en };
  const { lang } = useGlobal();
  const { metadata, reloadTrigger } = useInterface();
  const currentLocale = lang.split("-")[0];

  const metadataRef = useRef(null); // Ref to store latest valid metadata

  // Track metadata changes and store the latest valid one
  useEffect(() => {
    if (metadata?.multiChain?.chainList?.length) {
      metadataRef.current = metadata; // Update ref with valid metadata
    }
  }, [metadata]);

  // Use the latest metadata from the ref
  const getActiveMetadata = useCallback(() => {
    return metadata?.multiChain?.chainList?.length ? metadata : metadataRef.current;
  }, [metadata]);

  // Generate config using useMemo with synchronized metadata
  const config = useMemo(() => {
    const activeMetadata = getActiveMetadata(); // Get the latest metadata
    const metadataChainList = activeMetadata?.multiChain?.chainList;

    const chains = metadataChainList
      ?.filter((chain) => fullChains.find((fc) => +fc.id === +chain.chainId))
      ?.map((k) => ({
        ...k,
        chainId: +k?.chainId,
        id: +k?.chainId,
      }));

    if (!chains?.length) {
      return undefined;
    }

    if (metadataChainList[0]?.rpcUrl) {
      return genConfig(chains, chains.length > 1, metadataChainList);
    }
    return genConfig(chains, chains.length > 1);
  }, [getActiveMetadata, reloadTrigger]);

  // 保存语料信息到全局变量 src/hooks/useTranslation.jsx 需要用到
  // eslint-disable-next-line no-underscore-dangle
  window.__messages = messages[lang] || {};

  // Handle the case when config is undefined
  if (!config) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div>:(</div> {/* Optional: Loading state */}
      </div>
    );
  }

  return (
    <ReloadProvider>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <IntlProvider messages={messages[lang] || {}} locale={currentLocale} defaultLocale={currentLocale} onError={() => {}}>
            <TooltipProvider>
              <RouterProvider router={createBrowserRouter(routes)} />
              <ResponseErrorToast />
            </TooltipProvider>
          </IntlProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </ReloadProvider>
  );
}
export default App;
