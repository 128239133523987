import useSymbol from "@/stores/symbol";
import { Navigate } from "react-router";

const SymbolRedirect = () => {
  const { currentSymbol } = useSymbol();
  return <Navigate to={"/trade/" + currentSymbol} replace />;
};

SymbolRedirect.propTypes = {};

export default SymbolRedirect;
