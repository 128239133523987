import { getSiteMessageApi, readSiteMessageApi } from "@/apis/api/notification";
import { useRequest } from "ahooks";

/**
 * Fetches site messages
 * @param {Object} requestParam
 * @param {Object} requestParam.params
 * @param {String} requestParam.params.size value must be >0  && <=100
 * @param {String} [requestParam.params.offsetData] Pagination offset to fetch. If not provided or is an empty string, the first page is fetched.
 * @param {Object} useRequestConfigParam
 * @returns {Object}
 */
export const useGetSiteMessageRequest = (requestParam, useRequestConfigParam) => {
  return useRequest(() => getSiteMessageApi(requestParam).then((res) => res.data), useRequestConfigParam);
};

/**
 * Triggers the notification to be marked as read
 * @param {Object} requestParam
 * @param {Array<String>} requestParam.messageIdList List of message IDs that need to be marked as read.
 *  @returns {Object}
 */
export const useReadSiteMessageRequest = (requestParam, useRequestConfigParam) => {
  return useRequest(() => readSiteMessageApi(requestParam).then((res) => res.data), useRequestConfigParam);
};
