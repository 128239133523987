/* eslint-disable */

import { CustomEvents } from "@/constants/customEvents";
import useInterface from "@/stores/interface";
import { genApiSignature, getPrivateSignatureLocalInfo } from "@/utils/onboarding";

/**
 * 使用：
 * - 公有推送
 *  1、创建实例：
 *    const WS = new WSServer('wss://x.x.x/realtime_public');
 *  2、订阅：
 *    WS.subscribe('topicName', handler);
 *      + 有对应 topicName 的消息时，自动调用 handler，并传入 Object 数据：{ type: string; data: any; timestamp: number };
 *      + 同一个 topicName, 可多次订阅，回调不会重复
 *  3、取消订阅：
 *    WS.unsubscribe('topicName', handler);
 *      + handler 必须为 订阅时的 函数引用，该功能类似 addEventListener/removeEventListener。代码内注意 handler 的声明、引用
 *      + 当相同主题有多个订阅回调时，仍有订阅未完全取消时，不向后台发送 取消订阅消息，只断开回调
 *
 * 二 私有推送
 *  1、创建实例
 *    const WS = new WSServer('wss://x.x.x/realtime_public',signature);
 *
 */
export default class WSServer {
  constructor(link, isPrivate) {
    this.socket = null;
    this.link = link; // ws 请求 url
    this.isArtificialClosed = false; // 是否是主动关闭
    this.msgHandleMap = {}; // 消息回调列表
    this.pulseTimer = null; // 心跳计时器
    this.pulseInterval = 13000; // 客户端发送pong间隔时间（毫秒）
    this.reader = new FileReader();
    this.onLine = false; // 是否在线
    this.needReSubscribeMsgList = []; // [[topic, armMsg]]
    this.isPrivateWS = isPrivate; // 是否私有推送
    this.reConnectFlag = 0; // 重连次数
    this.reConnectTimer = null; // 重连计时器
    this.maxReConnectTimes = 4; // 最大重连次数 （5次：从0开始计算）
    this.onlineCheckTimer = null; // 在线检测定时器
    this.onlineCheckDuration = 3000; // 在线检测间隔时间
    this.onLineListeners = []; // 连接成功后的监听事件列表
    this.serverTimestamp = ""; // 服务器ping时间戳

    if (!this.isPrivateWS) {
      this.init();
    }

    this.subscribedTopics = new Set(); // Store all subscribed topics
  }

  logout = () => {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
    this.isArtificialClosed = true;
    this.clearPulse();
  };

  /**
   * 订阅 topic 消息
   * 当有对应 topic 消息返回是 调用 msgHandle
   * 1）类同 DOM.addEventListener，可以为相同 topic 订阅多个回调
   * 2）订阅相同 topic 时，当前方案为先取消之前的订阅，再重新订阅，否则 > 1 的相同 topic 订阅 只发送 delta 包，数据缺少
   * @param topic
   * @param msgHandle
   */
  subscribe = (topic, msgHandle) => {
    if (this.link) {
      if (!this.subscribedTopics) this.subscribedTopics = new Set(); // Ensure initialization

      if (this.msgHandleMap[topic]) {
        if (!this.msgHandleMap[topic].handles.includes(msgHandle)) {
          this.msgHandleMap[topic].handles.push(msgHandle);
        }
      } else {
        this.msgHandleMap[topic] = { handles: [msgHandle] };
        this.subscribedTopics.add(topic); // Record the subscribed topic
        this.putData(topic, "subscribe");
      }
    }
  };

  // 类同 DOM.removeEventListener
  unsubscribe = (topic, msgHandle, forceClear) => {
    if (!msgHandle || typeof msgHandle !== "function") {
      console.error("The unsubscribe works like removeEventListener, need second argument same with subscribe handle");
      return;
    }
    if (this.msgHandleMap[topic]) {
      const handleIdx = this.msgHandleMap[topic].handles.indexOf(msgHandle);
      if (handleIdx !== -1) {
        this.msgHandleMap[topic].handles.splice(handleIdx, 1);
      }
      if (!this.msgHandleMap[topic].handles.length || forceClear) {
        delete this.msgHandleMap[topic];
        this.subscribedTopics.delete(topic); // Remove the topic from the record
        this.putData(topic, "unsubscribe");
      }
    }
  };

  reConnect = () => {
    if (!this.onLine) {
      if (this.reConnectFlag >= this.maxReConnectTimes) {
        console.warn("stop reConnect: ", this.link);
        this.clearPulse();
      } else {
        if (this.reConnectTimer) {
          clearTimeout(this.reConnectTimer);
        }
        this.reConnectTimer = setTimeout(() => {
          if (this.socket) {
            this.socket.close();
            this.socket = null;
          }
          console.info("reConnect: ", this.link);
          this.setNeedReSendInfo();
          this.connect();
          this.dealWithException();
          this.reConnectFlag += 1;
          this.reConnectTimer = null;
        }, 4 * this.reConnectFlag * 1000);
      }
    }
  };

  observeVisibilityHandler = () => {
    document.addEventListener("visibilitychange", this.handleVisibilityChanged);
  };

  rmObserveVisibilityHandler = () => {
    document.removeEventListener("visibilitychange", this.handleVisibilityChanged);
  };

  handleVisibilityChanged = () => {
    if (!document.hidden && !this.onLine) {
      this.reConnectFlag = 0;
      this.reConnect();
    }
  };

  closedHandler = () => {
    this.onLine = false;
    if (this.socket) {
      this.socket.onmessage = null;
      this.socket.close();
    }
    this.socket = null;
    if (!this.isArtificialClosed) {
      this.reConnect();
    }
  };

  checkOnLine = () => {
    if (this.onlineCheckTimer) {
      clearInterval(this.onlineCheckTimer);
    }

    this.onlineCheckTimer = setInterval(() => {
      if (!this.onLine) {
        if (this.reConnectFlag >= this.maxReConnectTimes) {
          window.dispatchEvent(new CustomEvent(CustomEvents["ws-connect-error"], { detail: {} }));
        }
      } else {
        clearInterval(this.onlineCheckTimer);
        this.onLineListeners.forEach((l) => l());
      }
    }, this.onlineCheckDuration);
  };

  ready = (callback) => {
    if (this.onLine) return callback();
    if (!this.onLineListeners.includes(callback)) {
      this.onLineListeners.push(callback);
    }
  };

  init = () => {
    if (window.WebSocket) {
      if (this.link) {
        this.connect();
        this.dealWithException();
        this.observeVisibilityHandler();
        this.checkOnLine();
      } else {
        console.error("Please input WebSocket URL");
      }
    } else {
      console.error("This browser does not support WebSocket, please use another browser");
    }
  };

  connect() {
    if (!this.onLine && this.link) {
      const link = this.link;
      this.isArtificialClosed = false;
      if (this.isPrivateWS) {
        const { currentActiveApiKey, timestamp: curTimeStamp, accountId } = getPrivateSignatureLocalInfo();
        if (!accountId || !currentActiveApiKey?.secret) return;
        const url = `${link}${link.includes("?") ? "&" : "?"}accountId=${accountId}&terType=1`;
        if (currentActiveApiKey?.secret) {
          const requestBody = `accountId=${accountId}&terType=1`;
          const signParams = {
            timestamp: curTimeStamp,
            httpMethod: "GET",
            requestUri: "/api/v1/private/ws",
            requestBody: requestBody,
            secret: currentActiveApiKey?.secret,
          };
          const signature = genApiSignature(signParams);
          const headerKey = "Tetadex";
          const wssSignParam = {
            ["X-" + headerKey + "-Api-Key"]: currentActiveApiKey?.apiKey,
            ["X-" + headerKey + "-Passphrase"]: currentActiveApiKey?.passphrase,
            ["X-" + headerKey + "-Signature"]: signature,
            ["X-" + headerKey + "-Timestamp"]: curTimeStamp,
            ["platform"]: "web",
          };

          // 对wssSignSignature取json并做一次 base64 编码，并去除=
          const wssSignSignature = btoa(JSON.stringify(wssSignParam)).replaceAll("=", "");
          this.socket = new WebSocket(url, wssSignSignature);
        }
      } else {
        const url = `${link}${link.includes("?") ? "&" : "?"}terType=1`;
        this.socket = new WebSocket(url);
      }
      if (this.socket) {
        this.dealWithMsg();
        this.makePulse();
        // this.resubscribeAllTopics(); // Resubscribe to all topics after connection
      }
    }
  }

  /**
   * Resubscribe to all previously subscribed topics
   */
  // resubscribeAllTopics = () => {
  //   if (this.subscribedTopics && this.subscribedTopics.size > 0) {
  //     this.subscribedTopics.forEach((topic) => {
  //       this.putData(topic, "subscribe");
  //     });
  //   }
  // };

  /**
   * 封装【待重新发送到服务端信息】列表
   */
  setNeedReSendInfo() {
    this.needReSubscribeMsgList.length = 0;
    let topicList = Object.keys(this.msgHandleMap);
    if (topicList.length) {
      topicList.forEach((topic) => {
        this.msgHandleMap[topic].hasSubscribed = false;
        const msg = {
          type: "subscribe",
          channel: topic,
        };
        this.needReSubscribeMsgList.push([topic, JSON.stringify(msg)]);
      });
    }
  }

  /**
   * 消息分类处理
   */
  dealWithMsg() {
    if (this.socket) {
      this.socket.onmessage = (events) => {
        const resObj = JSON.parse(events.data || "{}");

        if (resObj.type === "ping" && resObj.time) {
          this.serverTimestamp = resObj.time;
        } else {
          let results = events.data;
          // 当前不使用 Blob 格式，使用时需深度优化
          if (results instanceof Blob) {
            this.reader.readAsText(results, "UTF-8");
            this.reader.onload = () => {
              try {
                results = JSON.parse(this.reader.result);
                this.triggerHandle(results);
              } catch (e) {
                console.error("Error parsing Blob data:", e);
              }
            };
          } else {
            try {
              this.triggerHandle(resObj);
            } catch (e) {
              console.error("Error handling message:", e);
            }
          }
        }
      };
    }
  }

  /**
   * 服务端返回消息，触发对应 topic 缓存的【回调列表】
   * @param results
   */
  triggerHandle(results) {
    const { channel, type, content, ts } = results;
    const msgHandles = this.msgHandleMap[channel]?.handles;
    if (msgHandles && msgHandles.length && type !== "subscribed") {
      msgHandles.forEach((handle) => {
        handle({
          type,
          timestamp: ts,
          ...content,
        });
      });
    }
    document.dispatchEvent(new CustomEvent(CustomEvents["ws-message"], { detail: results }));
  }

  /**
   * 向服务端发送消息
   * 1、当前仅需支持订阅 - subscribe 和 取消订阅 - unsubscribe
   * 2、当发送订阅时，如果和服务端并未建立连接，则缓存该订阅消息
   * @param topic
   * @param op
   */
  putData(topic, type) {
    const msg = { type, channel: topic };
    if (this.onLine && this.socket) {
      this.socket.send(JSON.stringify(msg));
    }
  }

  /**
   * 定时发送脉搏消息
   * 通过定期发送 "pong" 消息来维持 WebSocket 连接的活跃状态，防止连接因长时间没有活动而被关闭
   * 1、无需关注服务端返回，直接定时发送消息即可。（服务端会判断脉搏发送的时间戳是否合法、并判断是否要断连）
   * 2、连接被动断开时 this.onLine 和 this.socket 都为布尔 false，此时清除定时器并重连
   */
  makePulse() {
    // 清除之前的定时器，确保不会有多个定时器同时运行
    this.clearPulse();

    // 设置一个定时器，每隔 pulseInterval 发送一次 "pong" 消息
    this.pulseTimer = setInterval(() => {
      // 确保 WebSocket 连接是在线的，并且 socket 对象存在
      if (this.onLine && this.socket) {
        // 获取当前的本地时间戳
        let localTimestamp = useInterface.getState()?.serverClock?.getAdjustedIsoTimestamp;

        // 【矫正时间】 和 【服务器ping下发时间】 的差异超过100秒，则使用 ping 时间戳
        if (this.serverTimestamp && Math.abs(localTimestamp - Number(this.serverTimestamp)) > 100000) {
          localTimestamp = this.serverTimestamp;
        }

        // 创建一个 "pong" 消息对象，包含当前时间戳
        const info = {
          type: "pong",
          time: "" + localTimestamp,
        };
        // 通过 WebSocket 连接发送 "pong" 消息
        this.socket.send(JSON.stringify(info));
      } else {
        // 如果连接断开，清除定时器
        this.clearPulse();
      }
    }, this.pulseInterval);
  }

  /**
   * 清除发送脉搏消息的定时器
   */
  clearPulse() {
    if (this.pulseTimer) {
      clearInterval(this.pulseTimer);
      this.pulseTimer = null;
    }
  }

  /**
   * 异常处理
   * 1、连接建立时，
   *    1）公有推送启动脉搏消息
   *    2）私有推送发送鉴权消息，并启动脉搏消息
   *    3）发送待订阅列表（主动订阅时，存在调用时推送并没有建立连接，这时会缓存入待订阅列表，此处发送该列表消息）
   * 2、连接出错、或被动中断重置全局连接状态相关的变量
   * 3、页面卸载，中断关闭连接
   */
  dealWithException() {
    if (this.socket) {
      this.socket.onopen = () => {
        this.reConnectFlag = 0;
        this.onLine = true;
        // this.resubscribeAllTopics(); // Resubscribe to all topics on reconnect
      };
      this.socket.onerror = () => {
        console.warn("WebSocket error: ", this.link);
        this.closedHandler();
      };
      this.socket.onclose = (ev) => {
        console.warn("WebSocket closed: ", ev.code, ev.reason || "null", this.link);
        this.closedHandler();
      };
      window.addEventListener("beforeunload", () => {
        this.clearPulse();
        this.rmObserveVisibilityHandler();
        if (this.socket) {
          this.socket.close();
        }
      });
    }
  }
}
