import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import ui from "@/constants/ui";

export function Toaster() {
  const { toasts } = useToast()
  return (
    (<ToastProvider>
      {toasts.map(function ({ id, title, description,descriptionClassName, action, ...props },i) {
        return (
          (<Toast key={id} {...props} style={ ui?.toastStyle? ui?.toastStyle({i,id,...props,}): {
            top: props.variant=="simple"?(40+(toasts.filter((t,ii) => t.variant=="simple" && ii<=i).length*60))+"px":(toasts.reduce((acc, t, index) => {
              return acc + ( (index>(i-1))?0:({"simple":0,"orderFailure":180,"orderSuccess":180}[t.variant] ?? 150) )
            }, 100) + "px"),
          }}>
            <div className="grid gap-2 w-full max-w-[80vw]">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription className={descriptionClassName} >{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>)
        );
      })}
      <ToastViewport />
    </ToastProvider>)
  );
}
