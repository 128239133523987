/**
 * global spinner
 */
import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

const Spinner = ({ className, children, iconClassName }) => {
  if (children) {
    return (
      <div className={twMerge("w-full h-full flex items-center justify-center ", className)}>
        {React.cloneElement(children, {
          className: twMerge("size-full", iconClassName),
        })}
      </div>
    );
  }
  return (
    <div className={twMerge("w-full h-full flex items-center justify-center ", className)}>
      <span className={twMerge("loading loading-spinner loading-md", iconClassName)}></span>
    </div>
  );
};

Spinner.displayName = "Spinner";
Spinner.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  children: PropTypes.node,
};

export default Spinner;
