import { useGetAllAccountAssetRequest } from "@/apis/query/subAccount";
import Icon from "@/components/Icon";
import { useToast } from "@/components/ui/use-toast";
import { DEFAULT_TXT } from "@/constants/trade";
import useTranslation from "@/hooks/useTranslation";
import arrowDown from "@/icons/arrow-down.svg";
import circleChecked from "@/icons/circle-checked-filled.svg";
import useSubAccount from "@/modules/account/hooks/subaccount";
import useSymbol from "@/stores/symbol";
import useUser from "@/stores/user";
import { shortStr, toThousandString } from "@/utils/funcs";
import { useHover } from "ahooks";
import { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { useAccount } from "wagmi";

const AccountSwitch = () => {
  const PRECISION = 4;

  const { account, address } = useAccount();
  const { isAlreadySigned, currentActiveAccount, account: accountList } = useUser();
  const sortedAccountList = accountList?.sort((a, b) => Number(a.createdTime) - Number(b.createdTime)) || [];
  const { toast: Toast } = useToast();
  const { switchAccount } = useSubAccount();
  const t = useTranslation();
  const { getCurrentSymbol } = useSymbol();
  const symbolInfo = getCurrentSymbol();
  const alreadySigned = isAlreadySigned();

  const { data, runAsync: getAllAccountAmount } = useGetAllAccountAssetRequest(
    {
      params: {
        accountId: accountList.length > 0 ? accountList[0].id : null,
        coinId: symbolInfo.quoteCoinId,
      },
    },
    {
      refreshDeps: [address, accountList.length],
      ready: accountList.length > 0 && !!address && alreadySigned,
    },
  );

  const getAccountAssets = (accountId, col) => {
    if (data && data.data && data.data[accountId] && col) {
      const amount = data.data[accountId][col];

      return amount ? toThousandString(amount, PRECISION) + " " + symbolInfo.quoteCoin : DEFAULT_TXT;
    }
    return DEFAULT_TXT;
  };

  const ref = useRef(null);
  const isHovering = useHover(ref);
  useEffect(() => {
    if (isHovering) {
      getAllAccountAmount();
    }
  }, [isHovering, getAllAccountAmount]);

  return (
    <>
      <div
        ref={ref}
        className="cursor-pointer inline-flex gap-2 items-center justify-between py-2 px-3 rounded-[12px] relative [&>.accounts]:hidden [&>.accounts]:hover:inline-flex hover:bg-bg-tertiary"
      >
        <img className="size-5 shrink-0" src={account?.connector?.icon} />
        <div className="flex-1 ">
          <div className="text-sm leading-[22px] font-medium">{shortStr(account?.address, 10)}</div>
          <div className="text-xs text-foreground-secondary leading-[16px] -tracking-[0.36px]">{currentActiveAccount?.clientAccountId}</div>
          <div className="text-xs text-foreground-secondary leading-[16px] -tracking-[0.36px] whitespace-nowrap">
            {getAccountAssets(currentActiveAccount?.id, "amount")}
            {/* {data?.data?.data?.availableAmount ? toThousandString(data?.data?.data?.availableAmount, symbolInfo.quoteCoinPrecision) : DEFAULT_TXT} {symbolInfo.quoteCoin} */}
          </div>
        </div>
        <Icon icon={arrowDown} className=" shrink-0 caret h-4 fill-foreground -rotate-90" />
        <div className="accounts absolute w-[190px]  right-full -top-2">
          <div className="w-full p-2 mr-[10px] bg-bg-secondary rounded-[12px] flex flex-col gap-1 [&>a]:inline-flex [&>a]:justify-between [&>a]:items-center [&>a]:p-3 [&>a]:rounded-[12px] hover:[&>a]:bg-bg-tertiary max-h-[80vh] [&>a]:min-h-auto overflow-auto no-scrollbar border-[0.5px] border-solid border-border-strong">
            {sortedAccountList.map((a, i) => (
              <a
                key={`A-${i}`}
                onClick={async () => {
                  const success = await switchAccount(a.clientAccountId);
                  success && Toast.show(t("switchAccountSuccess"));
                }}
              >
                <div>
                  <div className="text-sm leading-[22px] font-medium break-all text-wrap flex-1 text-foreground">{a.clientAccountId}</div>
                  <div className="text-xs leading-[16px] -tracking-[0.36px] text-foreground-secondary whitespace-nowrap">{getAccountAssets(a.id, "amount")}</div>
                </div>
                <Icon icon={circleChecked} className={twMerge("size-4 text-brand shrink-0", a.id == currentActiveAccount?.id ? "" : "invisible")} />
              </a>
            ))}
          </div>
        </div>
      </div>
      <hr className="h-px mt-2 mb-4 mx-3 bg-border-subtle border-0" />
    </>
  );
};

export default AccountSwitch;
