import { Buffer } from "buffer";
import { keccak256 } from "ethereum-cryptography/keccak";
import BN from "bn.js";
import { starkEc } from "./starkex-lib/lib/starkware";
import _ from "lodash";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";

/**
 * @description 用于规范化处理十六进制字符串，确保其长度为32字节（64个十六进制字符）
 * 这个函数主要用于在处理椭圆曲线密码学中的私钥等情景下，确保输入的十六进制字符串符合特定的长度要求
 * 函数的主要步骤如下：
 * 首先，调用了 stripHexPrefix 函数，将输入的十六进制字符串移除 0x 前缀，并将其转换为小写形式
 * 接着，使用 padStart 方法将十六进制字符串填充到长度为 64 的固定长度，不足部分用字符 "0" 填充
 * 然后，检查填充后的字符串长度是否为64个字符（32字节），如果不是，则抛出错误，提示输入的字符串长度不符合32字节的要求。
 * 最后，返回处理后的十六进制字符串。
 * @param {*} hex
 * @returns
 */
export function normalizeHex32(hex) {
  const paddedHex = stripHexPrefix(hex).toLowerCase().padStart(64, "0");
  if (paddedHex.length !== 64) {
    throw new Error("normalizeHex32: Input does not fit in 32 bytes");
  }
  return paddedHex;
}

export function bnToHex32(bn) {
  return normalizeHex32(bn.toString(16));
}

/**
 * @description 该函数将椭圆曲线密钥对转换为一个简单的密钥对对象，包括公钥和私钥的十六进制表示
 * 它接受一个椭圆曲线密钥对 ecKeyPair 作为参数
 * 函数的主要步骤是：
 * 获取椭圆曲线密钥对的私钥，确保私钥存在，如果不存在则抛出错误
 * 获取椭圆曲线密钥对的公钥，将公钥的 x 坐标和 y 坐标以及、私钥转换为十六进制
 * 返回一个包含公钥、公钥的 y 坐标和私钥的对象。
 * @param {*} ecKeyPair
 * @returns { object } publicKey、publicKeyYCoordinate、privateKey 返回 以不带 0x 前缀的 32 字节十六进制字符串形式
 */

export function asSimpleKeyPair(ecKeyPair) {
  const ecPrivateKey = ecKeyPair.getPrivate();
  if (_.isNil(ecPrivateKey)) {
    throw new Error("asSimpleKeyPair: Key pair has no private key");
  }
  const ecPublicKey = ecKeyPair.getPublic();
  return {
    publicKey: bnToHex32(ecPublicKey.getX()),
    publicKeyYCoordinate: bnToHex32(ecPublicKey.getY()),
    privateKey: bnToHex32(ecPrivateKey),
  };
}
export function hexToBn(hex) {
  return new BN(stripHexPrefix(hex), 16);
}

/**
 * @description 接受一个参数 privateKeyOrKeyPair，可以是一个私钥的字符串形式，也可以是一个包含私钥的对象
 * 该函数将输入的私钥转换为椭圆曲线密钥对对象，并返回该对象。
 * 函数的主要步骤如下：
 * 首先，它检查传入的参数类型，如果是字符串，则假定为私钥，如果是对象，则假定该对象包含私钥
 * 然后，使用 normalizeHex32 函数对私钥进行规范化处理。
 * 最后，使用 starkEc.keyFromPrivate 函数从规范化处理后的私钥创建一个椭圆曲线密钥对对象，并将其返回。
 * @param {*} privateKeyOrKeyPair
 * @returns
 */
export function asEcKeyPair(privateKeyOrKeyPair) {
  const privateKey = typeof privateKeyOrKeyPair === "string" ? privateKeyOrKeyPair : privateKeyOrKeyPair.privateKey;
  return starkEc.keyFromPrivate(normalizeHex32(privateKey));
}

/**
 * @description 用于移除十六进制字符串的前缀
 * 函数的主要步骤如下：
 * 首先，检查输入的字符串是否以 "0x" 开头
 * 如果是以 "0x" 开头，则使用 substr 方法去除前两个字符（即 "0x"）。
 * 如果不是以 "0x" 开头，则直接返回输入字符串。
 * @param {*} input
 * @returns
 */
export function stripHexPrefix(input) {
  if (input.indexOf("0x") === 0) {
    return input.substr(2);
  }
  return input;
}

/**
 * @description
 * asSimpleKeyPair 函数接受一个椭圆曲线密钥对 ecKeyPair 作为参数，并将其转换为一个简单的密钥对对象，
 * 包括公钥、公钥的 y 坐标和私钥的十六进制表示。它的主要步骤包括获取私钥、确保私钥存在、获取公钥、将公钥坐标转换为十六进制、将私钥转换为十六进制，最后返回密钥对对象。
 * asEcKeyPair 函数接受一个私钥或密钥对对象作为参数，并返回一个椭圆曲线密钥对对象。
 * 它的主要步骤是获取私钥，并对其进行规范化处理，然后返回一个椭圆曲线密钥对对象。
 * 主要用于处理椭圆曲线密码学中的密钥对，并提供了一种方便的方式将其转换为所需格式的对象。
 * @param {*} data
 * @returns
 */
export function keyPairFromData(data) {
  if (data.length === 0) {
    throw new Error("keyPairFromData: Empty buffer");
  }
  const hashedData = keccak256(data);
  const hashBN = hexToBn(Buffer.from(hashedData).toString("hex"));
  const privateKey = hashBN.iushrn(5).toString("hex"); // Remove the last five bits.
  return asSimpleKeyPair(asEcKeyPair(privateKey));
}

/**
 * @description 用于生成随机的客户端ID,识别不同的客户端或会话
 * 函数的主要步骤如下：
 * 首先，使用 Math.random() 方法生成一个0到1之间的随机小数
 * 然后，将这个随机小数转换为字符串形式，并使用 slice(2) 方法去除字符串的前两个字符，因为这两个字符是 "0."
 * 接着，使用 replace(/^0+/, "") 方法去除字符串开头的所有 0
 * 最后，返回生成的随机客户端ID。
 * @returns
 */
export function generateRandomClientId() {
  return Math.random().toString().slice(2).replace(/^0+/, "");
}

/**
 * @description 将可转换为 BigNumber 类型的值转换为 Solidity 中的 uint256 类型。
 * 确保将传递给智能合约的金额转换为符合 Solidity 中 uint256 类型要求的格式，以便在智能合约中进行正确的处理。
 * 函数的主要步骤如下：
 * 首先，将输入的参数 amount 转换为 BigNumber 类型的对象。
 * 然后，检查转换后的结果是否为整数，如果不是整数，则抛出错误，提示该金额不能用于合约调用。
 * 最后，将 BigNumber 类型的值转换为字符串形式，并且确保其为整数，并返回结果。
 * @param {*} amount
 * @returns
 */
function bignumberableToUint256(amount) {
  const result = BigNumber(amount);
  if (!result.isInteger()) {
    throw new Error(`Amount cannot be used in contract call: ${result.toFixed()}`);
  }
  return result.toFixed(0);
}

/**
 * @description 主要用于在智能合约中处理代币数量，确保其符合合约对数量格式的要求，并且考虑到代币的精度。将代币数量转换为 Solidity 中的 uint256 类型。
 * 函数的主要步骤如下：
 * 首先，将输入的 humanAmount 转换为 BigNumber 类型的对象。
 * 使用 shiftedBy 方法将该 BigNumber 对象的小数点向右移动 decimals 位，以匹配代币的精度。
 * 然后，将转换后的 BigNumber 对象传递给 bignumberableToUint256 函数进行进一步处理，确保其符合 Solidity 中 uint256 类型的要求。
 * 最后，返回转换后的结果。
 * @param {*} humanAmount
 * @param {*} decimals
 * @returns
 */
function humanTokenAmountToUint256(humanAmount, decimals) {
  return bignumberableToUint256(BigNumber(humanAmount).shiftedBy(+decimals));
}

/**
 * @description 主要用于在区块链智能合约中生成转移 ERC20 代币的事实（fact），以确保转移的唯一性和完整性。
 * 该函数接受一个包含转移参数的对象作为参数，包括接收者地址 recipient、代币地址 tokenAddress、代币精度 tokenDecimals、转移数量 humanAmount 和一个盐值 salt。
 * 函数的主要步骤如下：
 * 使用 Solidity 中的 soliditySha3 函数对传入的参数进行哈希计算。
 * 参数包括接收者地址（recipient）、代币数量（经过精度转换后的 humanAmount）、代币地址（tokenAddress）和一个盐值（salt）。
 * 在哈希计算过程中，需要将 humanAmount 转换为与 Solidity 中的 uint256 类型相匹配的格式，以确保精度一致性。
 * 返回计算得到的哈希结果
 * @param {*} param0
 * @returns
 */
export function getTransferErc20Fact({ recipient, tokenAddress, tokenDecimals, humanAmount, salt }) {
  const result = soliditySha3(
    { type: "address", value: recipient },
    {
      type: "uint256",
      value: humanTokenAmountToUint256(humanAmount, tokenDecimals),
    },
    { type: "address", value: tokenAddress },
    { type: "uint256", value: bignumberableToUint256(salt) },
  );
  return result;
}

/**
 * @description 主要用于在智能合约开发中生成唯一的哈希值，用于各种用途，例如生成合约地址、验证消息等。
 * 它接受任意数量的参数，并将它们打包成 Solidity 中的数据类型，然后计算它们的 SHA3 哈希值。
 * 函数的主要步骤如下：
 * 首先，它遍历输入参数，并提取每个参数的类型和值。
 * 对于 uint256 类型的参数，并且其值是 BigNumber 类型，它将其转换为十进制字符串，以确保与 Solidity 中的数据类型相匹配。
 * 对于 bytes32 类型的参数，并且其值是字符串，它确保字符串是以十六进制格式表示的，并将其转换为 Solidity 中的字节数组。
 * 使用 ethers.js 库的函数 ethers.utils.solidityPack 将打包后的参数转换为 Solidity 中的字节数组。
 * 最后，使用 ethers.utils.keccak256 函数对打包后的数据进行 SHA3 哈希计算，并返回结果。
 * @param  {...any} args
 * @returns
 */
export function soliditySha3(...args) {
  const types = args.map((arg) => arg.type);
  const values = args.map((arg) => {
    const value = arg.value;
    if (arg.type === "uint256" && BigNumber.isBigNumber(value)) {
      return value.toFixed();
    } else if (arg.type === "bytes32" && typeof value === "string") {
      return ethers.utils.hexZeroPad(ethers.utils.hexlify(ethers.utils.toUtf8Bytes(value)), 32);
    }
    return value;
  });
  // ethers
  const packed = ethers.utils.solidityPack(types, values);
  return ethers.utils.keccak256(packed);
}
