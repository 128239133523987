export default () => {
  return (id, values = {}) => {
    let result = "";
    try {
      // eslint-disable-next-line no-underscore-dangle
      const messages = window.__messages || {};
      const origin = messages?.[id] || id;
      result = origin?.replace(/\{\{(.*?)\}\}/gi, (m, k) => values?.[k] || "");
    } catch (err) {
      result = id;
    }
    return result;
  };
};
