import axios from "@/services/request";

export const notificationApi = {
  getSiteMessage: "/v1/private/user/getSiteMessagePage",
  readSiteMessage: "/v1/private/user/readSiteMessage",
};

/**
 * Fetches site messages
 * @param {Object} requestParam
 * @param {Object} requestParam.params
 * @param {String} requestParam.params.size value must be >0  && <=100
 * @param {String} [requestParam.params.offsetData] Pagination offset to fetch. If not provided or is an empty string, the first page is fetched.
 * @returns {Promise} Returns a Promise that resolves to an object containing the response data, or is rejected with an error.
 */
export const getSiteMessageApi = (requestParam) => {
  return axios.get(notificationApi.getSiteMessage, { ...requestParam, interceptError: false });
};

/**
 * Triggers the notification to be marked as read
 * @param {Object} requestParam
 * @param {Array<String>} requestParam.messageIdList List of message IDs that need to be marked as read.
 * @returns {Promise} Returns a Promise that resolves to an object containing the response data, or is rejected with an error.
 */
export const readSiteMessageApi = (requestParam) => {
  return axios.post(notificationApi.readSiteMessage, { ...requestParam, interceptError: false });
};
