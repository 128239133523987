import { CustomEvents } from "@/constants/customEvents";
import { disclaimerAndSignStepEnum } from "@/constants/wallets";
import useGlobal from "@/stores/global";
import useUser from "@/stores/user";

const useSubAccount = () => {
  const { keys, currentActiveAccount, setUser } = useUser();
  const { setGlobal } = useGlobal();

  // 账号切换 Promise.resolve(true) 成功，否则失败
  // account : clientAccountId
  const switchAccount = async (account) => {
    if (currentActiveAccount?.clientAccountId === account) return false; // 如果当前账户已经是要切换的账户，则不执行切换

    if (keys[currentActiveAccount?.ethAddress?.toLowerCase()]?.[account]?.l2Key) {
      // 如果要切换的账户已经签名
      setUser({ currentClientAccountId: account });
      return true;
    } else {
      // 未签名，弹出签名对话框
      setGlobal({ onboardingClientAccountId: account, disclaimerAndSignVisible: true, disclaimerAndSignStep: disclaimerAndSignStepEnum.sign });

      // onboard 成功，先触发 account-onboard 事件，然后 set({disclaimerAndSignVisible:false})
      // 监听到 disclaimerAndSignVisible = false  onboarding 失败
      const res = new Promise((resolve) => {
        const onboardHandler = (e) => {
          setUser({ currentClientAccountId: e.detail?.clientAccountId });
          resolve(true);
        };
        window.addEventListener(CustomEvents["account-onboard"], onboardHandler, false);
        const unsubscribe = useGlobal.subscribe((state) => {
          if (!state.disclaimerAndSignVisible) {
            unsubscribe();
            window.removeEventListener(CustomEvents["account-onboard"], onboardHandler);
            resolve(false);
          }
        });
      });
      return res;
    }
  };

  // 账号签名 Promise.resolve(true) 成功，否则失败
  // account : clientAccountId
  const signAccount = async (account) => {
    if (keys[currentActiveAccount?.ethAddress?.toLowerCase()]?.[account]?.l2Key) {
      return true;
    } else {
      // 未签名，弹出签名对话框
      setGlobal({ onboardingClientAccountId: account, disclaimerAndSignVisible: true, disclaimerAndSignStep: disclaimerAndSignStepEnum.sign });

      // onboard 成功，先触发 account-onboard 事件，然后 set({disclaimerAndSignVisible:false})
      // 监听到 disclaimerAndSignVisible = false  onboarding 失败
      const res = new Promise((resolve) => {
        const onboardHandler = () => {
          resolve(true);
        };
        window.addEventListener(CustomEvents["account-onboard"], onboardHandler, false);
        const unsubscribe = useGlobal.subscribe((state) => {
          if (!state.disclaimerAndSignVisible) {
            unsubscribe();
            window.removeEventListener(CustomEvents["account-onboard"], onboardHandler);
            resolve(false);
          }
        });
      });
      return res;
    }
  };

  return {
    switchAccount,
    signAccount,
  };
};

export default useSubAccount;
