import PropTypes from "prop-types";
function Footer({ className }) {
  return (
    <div className={`${className} module footer w-full h-10 flex items-center justify-between px-4 text-[--foreground-tertiary] text-xs !rounded-b-none`}>
      <span>
        {process.env.REACT_APP_SYSTEM_NAME} @ {new Date().getFullYear()}
      </span>
      <span className="inline-flex items-center gap-1" rel="noreferrer">
        POWERED BY {process.env.REACT_APP_SYSTEM_NAME}
      </span>
    </div>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
