import Spinner from "@/components/Spinner";
import Clock from "@/components/trade/lib/clock";
import useInterface from "@/stores/interface";
import { useRequest } from "ahooks";
import request from "axios";
import PropTypes from "prop-types";
/**
 * 请求 metadata
 */
function Bootstrap({ children }) {
  const { setInterface, metadata } = useInterface();

  const { loading: loadingMeta, error: metaError } = useRequest(
    () =>
      request("/v1/public/meta/getMetaData").then((res) => {
        // Check if the response is actually an HTML page indicating a 404 error
        if (res.headers["content-type"]?.includes("text/html")) {
          throw new Error("API not found (404)");
        }
        const timeRes = parseInt(res.data.responseTime, 10);
        const reqTimestamp = Date.now();
        const serverTimeGap = timeRes - reqTimestamp;
        const serverClock = new Clock(serverTimeGap, reqTimestamp, timeRes);
        setInterface({ metadata: res.data.data, serverClock });
      }),
    {
      refreshDeps: !metadata,
    },
  );
  return loadingMeta ? <Spinner className="flex-1" /> : metaError ? <div className="flex-1 flex items-center justify-center text-lg">:(</div> : children;
}

Bootstrap.propTypes = {
  children: PropTypes.any,
};

export default Bootstrap;
