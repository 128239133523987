import Icon from "@/components/Icon";
import ui from "@/constants/ui";
import Caret from "@/icons/caret.svg";
import { useClickAway } from "ahooks";
import PropTypes from "prop-types";
import { useRef } from "react";
import { twMerge } from "tailwind-merge";

// 顶部链接，支持下拉菜单
function HeaderDropDown({ title, children, className = "", contentClassName = "", titleClassName = "", onClick = () => {}, ...rest }) {
  const refDd = useRef(null);
  useClickAway(() => {
    refDd.current?.parentNode.removeAttribute("open");
  }, refDd);
  return (
    <details
      className={twMerge(
        "dropdown [&>summary>.caret]:open:rotate-180 [&>summary>.caret]:open:fill-current [&>summary]:open:text-primary [&>summary]:open:bg-foreground [&>summary]:open:bg-opacity-10 [&>ul:empty]:open:hidden",
        ui?.dropdownClassName,
        className,
      )}
      {...rest}
    >
      <summary
        ref={refDd}
        tabIndex="0"
        role="button"
        onClick={onClick}
        className={twMerge(
          "m-0 btn btn-ghost btn-sm font-normal rounded-none h-full text-foreground hover:text-primary [&>.caret]:hover:fill-primary hover:bg-foreground hover:bg-opacity-10",
          ui?.dropdownTitleClassName,
          titleClassName,
        )}
      >
        <div>{title}</div>
        {rest.showCaret && <Icon icon={Caret} className="caret h-4" />}
      </summary>
      <ul
        tabIndex="0"
        className={twMerge(
          "px-0 py-4 rounded-[12px] menu dropdown-content bg-[--dropdown] z-[10] min-w-full box-border [&>a]:px-3 [&>a]:min-h-12 [&>a]:inline-flex [&>a]:items-center [&>a]:text-foreground [&>a]:whitespace-nowrap [&>a]:cursor-pointer hover:[&>a]:fill-primary hover:[&>a]:bg-[highlight]  bg-bg-secondary my-2 p-2 [&>a+a]:mt-0.5 [&>a]:rounded-[12px] [&>a.selected]:bg-bg-tertiary hover:[&>a]:bg-bg-tertiary hover:[&>a]:text-foreground border-[0.5px] border-solid border-border-strong",
          ui?.dropdownContentClassName,
          contentClassName,
        )}
      >
        {children}
      </ul>
    </details>
  );
}

HeaderDropDown.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  onClick: PropTypes.func,
};

export default HeaderDropDown;
