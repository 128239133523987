import Icon from "@/components/Icon";
import languages from "@/constants/languages";
import ui from "@/constants/ui";
import language from "@/icons/language.svg";
import HeaderDropDown from "@/modules/layout/components/HeaderDropDown";
import useGlobal from "@/stores/global";
import useUser from "@/stores/user";
import axios from "axios";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { twMerge } from "tailwind-merge";
function LangSwitch(props) {
  const { setGlobal, lang } = useGlobal();
  const { currentActiveAccount } = useUser();
  const { className, ...rest } = props;
  // 设置语言为 user.language
  useEffect(() => {
    if (currentActiveAccount?.user?.language) {
      const lang = currentActiveAccount?.user?.language;
      const correctedLanguage = languages.filter((row) => ui?.enabledLanguages?.includes(row.lang)).find((l) => l.lang == lang) ? lang : "en-US";
      setGlobal({ lang: correctedLanguage });
    }
  }, [currentActiveAccount?.user?.language]);

  return (
    <HeaderDropDown
      title={<Icon icon={language} className="size-6 fill-foreground" />}
      className={twMerge(ui?.headerIconLinkClassName, className)}
      titleClassName={twMerge(ui?.headerIconLinkClassName, className)}
      {...rest}
    >
      {languages
        .filter((row) => ui?.enabledLanguages?.includes(row.lang))
        .sort((a, b) => ui?.enabledLanguages.indexOf(a.lang) - ui?.enabledLanguages.indexOf(b.lang))
        .map((e) => (
          <a
            key={e.lang}
            className={e.lang === lang ? "selected" : ""}
            onClick={async (evt) => {
              setGlobal({ lang: e.lang });
              evt.preventDefault();
              axios
                .post(
                  "/v1/private/user/updateUser",
                  {
                    language: e.lang,
                    nickname: currentActiveAccount?.user?.nickname,
                    email: currentActiveAccount?.user?.email,
                  },
                  { interceptError: false },
                )
                // eslint-disable-next-line no-console
                .catch(console.error);
            }}
          >
            {languages.find((l) => l.lang == e.lang).name}
          </a>
        ))}
    </HeaderDropDown>
  );
}
LangSwitch.propTypes = {
  className: PropTypes.string,
};

export default LangSwitch;
