import Spinner from "@/components/Spinner";
import SymbolRedirect from "@/modules/trade/pages/SymbolRedirect";
import React, { Suspense } from "react";

export default ({ routes }) => {
  const rootRoute = routes.filter((r) => r.path === "/")[0];
  rootRoute.children.push({
    path: "trade",
    element: <SymbolRedirect />,
  });
  rootRoute.children.push({
    path: "trade/:symbol",
    element: <Suspense fallback={<Spinner className="min-h-[90vh]" />}>{React.createElement(React.lazy(() => import("./pages/Trade")))}</Suspense>,
  });
};
