export default {
  enabledLanguages: ["en-US", "zh-CN", "zh-TW"],
  symbolPopoverContentProps: {
    sideOffset: 16,
    alignOffset: 0,
  },
  symbolPopoverContentClassName: "h-[calc(100vh-136px)]",
  symbolPopoverTabs: ["all_tab", "favorite_tab"],
  // symbolPopoverTabs: ["favorite_tab", "all_tab", "USDT_tab"],
  symbolPopoverContentTableBodyRowClassName: "hover:!bg-bg-tertiary",
  addFavCol: "left",
  headerDropDownIconTitleClassName: "[&_img]:size-5 [&_.spinner]:size-5",
  headerDropDownIconItemClassName: "[&_.icon]:size-5",
  connectWalletBtnClassName: "min-h-10 h-10 px-4",
  joinFormControlClassName:
    "bg-bg-secondary border-bg-secondary hover:border-border-strong focus-within:border-border-strong rounded-xl has-[[data-state=open]]:border-border-strong data-[error=on]:border-error data-[error=on]:focus-within:border-error has-[input:disabled]:opacity-50 has-[input:disabled]:border-none",
  orderInputClassName: "bg-bg-secondary border-bg-secondary hover:border-border-strong focus-within:border-border-strong rounded-xl",
  tableClassName: "[--b1:19.8%_0.01_285.46]", // #15151a
  tableHeaderRowClassName: "[&>th]:text-foreground-tertiary",
  tabsPrimitiveTriggerClassName:
    "text-foreground-secondary font-normal data-[state=active]:font-normal data-[state=active]:text-foreground data-[state=active]:fill-foreground-primary transition-none",
  historyTabs: ["orderBook", "recentTrades"],
  tooltipContentClassName: "px-3 py-2 rounded-lg",
  selectViewPortClassName: "p-2 [&>div+div]:mt-0.5",
  selectItemClassName: "hover:bg-bg-tertiary rounded-lg min-h-8 px-3 box-border focus:bg-tertiary focus:text-foreground data-[state=selected]:bg-bg-tertiary",
  selectTriggerClassName:
    "border border-solid box-border border-bg-secondary bg-bg-secondary hover:border-border-strong focus-within:border-border-strong bg-bg-secondary rounded-xl [&.input-ghost]:border-0 [&.input-ghost]:bg-transparent data-[state=open]:border-border-strong text-nowrap data-[error=on]:border-error data-[error=on]:focus-within:border-error",
  selectGhostClassName:
    "input-ghost input-sm border-transparent data-[state=open]:border-transparent text-xs px-0 focus-within:outline-none focus-within:border-transparent focus-within:bg-transparent py-0",
  selectContentClassName: "rounded-xl",
  formClassName: "[&>section]:min-h-5",
  toastDefaultClassName: "top-[66px] right-3 rounded-xl bg-bg-secondary border-border-subtle  text-foreground-secondary",
  toastOrderSuccessClassName: "top-[66px] right-3 rounded-xl bg-bg-secondary border-border-subtle  text-foreground-secondary py-4 border-l-[24px] border-l-primary",
  toastOrderFailureClassName: "top-[66px] right-3 rounded-xl bg-bg-secondary border-border-subtle  text-foreground-secondary py-4 border-l-[24px] border-l-warning",
  toastCloseClassName: "right-6 top-6",
  toastTitleClassName: "text-xl leading-6 text-foreground",
  toastTitleIconClassName: "size-6",
  toastDescriptionClassName: "opacity-100 text-inherit",
  toastSimpleClassName: "top-[66px] px-4 py-3 rounded-full text-foreground bg-bg-secondary border-border-subtle",
  depositBtnClassName: "[--btn-color:0.8698_0.021_279.89] !text-xs", // oklch(86.98% 0.021 279.89) <-> #d1d3e2
  withdrawBtnClassName: "!text-xs",
  indexPriceSourceTableContainerClassName: "[--highlight:var(--border-gutter)]",
  indexPriceSourceTableHeaderRowClassName: "!bg-bg-secondary [&>th]:text-foreground-secondary",
  indexPriceSourceTableClassName: "text-xs",
  indexPriceSourceTableBodyRowClassName: "border-[--border-gutter] [&>td]:text-foreground",
  viewHeaderTooltipContentProps: { sideOffset: 8 },
  tabsContentClassName: "pt-0",
  dropdownClassName: "hover:bg-neutral open:bg-neutral rounded-lg hover:rounded-lg border border-solid border-transparent hover:border-border-strong transition-all [&>summary]:open:text-foreground",
  dropdownTitleClassName: "rounded-lg text-foreground hover:text-foreground px-2",
  headerIconLinkClassName: "!size-10 inline-flex items-center justify-center rounded-full hover:rounded-full hover:bg-bg-secondary !border-none !p-0 cursor-pointer",
  positionTableClasses: {
    containerClassName: "mt-2",
    tableClassName: "table-pin-cols text-xs",
    bodyRowClassName: "border-none [&_td]:text-nowrap [&_td]:text-foreground [&>*]:h-14 [&>*]:leading-1 [&>*:not(:first-child)]:text-left [&>*:not(:first-child):last-child]:text-right",
    headerRowClassName: "[&>*]:text-foreground-secondary [&>*]:h-6 [&>*:not(:first-child)]:text-left [&>*:not(:first-child):last-child]:text-right",
  },
  positionTableButtonClassName: "btn btn-neutral btn-sm px-4 !text-xs text-foreground !font-normal  text-nowrap",
  dashboardTableButtonClassName: "btn btn-neutral btn-sm px-4 !text-sm text-foreground !font-normal text-nowrap",
  helperClassName: "tooltip-text",
  dashboardTableClasses: {
    headerRowClassName:
      "border-b border-t border-solid border-[--highlight] !bg-inherit [&>*]:h-[54px] [&>*:not(:first-child)]:text-left [&>*:not(:first-child):last-child]:text-right text-xs !bg-bg-primary",
    bodyRowClassName: "hover-radius [&>*]:h-[54px] [&>*]:leading-1 [&>*:not(:first-child)]:text-left [&>*:not(:first-child):last-child]:text-right text-xs",
  },
  marketTableClasses: {
    headerRowClassName: "border-b border-solid border-[--highlight] !bg-inherit [&>*]:h-[54px]  [&>*:last-child]:text-left  [&>*:not(:first-child)]:text-left text-xs !bg-bg-primary",
    bodyRowClassName: "hover-radius [&>*]:h-[54px] [&>*]:leading-1 [&>*:not(:first-child)]:text-left [&>*:not(:first-child):last-child]:text-right text-xs",
  },
  dashBoardPositionTableClasses: {
    headerRowClassName: "border-b border-solid border-[--highlight] !bg-inherit [&>*]:h-[33px]  [&>*:last-child]:text-left  [&>*:not(:first-child)]:text-left text-xs !bg-bg-primary",
    bodyRowClassName: "hover-radius [&>*]:h-[54px] [&>*]:leading-1 [&>*:not(:first-child)]:text-left [&>*:not(:first-child):last-child]:text-right text-xs",
  },
  dashboardSelectClasses: {
    className: "text-xs",
    selectItemClassName: "text-xs",
  },
  referralYourClasses: {
    headerRowClassName:
      "border-b border-t border-solid border-[--highlight] !bg-inherit [&>*]:h-[54px] [&>*:not(:first-child)]:text-left [&>th:first-child]:pl-0 [&>*:not(:first-child):last-child]:text-right text-xs !bg-bg-primary [&>th:last-child]:pr-0",
    bodyRowClassName:
      "hover-radius [&>*]:h-[54px] [&>*]:leading-1 [&>*:not(:first-child)]:text-left [&>*:first-child]:pl-0  [&>*:not(:first-child):last-child]:text-right text-xs [&>*:not(:first-child):last-child]:pr-0",
  },
  toastLimit: 5,
  viewHeaderHelperClassName: "border-b-border-strong",
  viewHeaderFields: ["dailyChange", "oraclePrice", "indexPrice", "dailyHigh", "dailyLow", "dailyTurnover", "dailyVolume"],
  alertDialogDescriptionClassName: "text-sm",
  viewHeaderClassName: "mt-1",
  defaultShowEqualValInput: false,
  dialogContentClassName: "rounded-3xl gap-0 p-8 outline-none",
  dialogTitleClassName: "text-xl leading-7 px-0 pt-0 pb-2 mx-0 border-0 font-semibold tracking-tight",
  dialogCloseClassName: "top-9 right-8 [&>svg]:size-5",
  dialogFooterClassName: "sm:space-x-3 pt-4",
  dialogFooterButtonClassName: "btn text-sm !font-normal h-[42px] min-h-[42px]",
  deepValueClassName: "bg-bg-secondary border-transparent focus:border-transparent",
  dialogScrollableBodyClassName: "max-h-[calc(100vh_-_240px)] no-scrollbar overflow-auto",
};
