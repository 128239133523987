import * as React from "react"
import * as ToastPrimitives from "@radix-ui/react-toast"
import { cva } from "class-variance-authority";
import { X } from "lucide-react"

import { cn } from "@/utils/cn"
import ui from "@/constants/ui";

const ToastProvider = ToastPrimitives.Provider

const ToastViewport = React.forwardRef(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      "fixed top-0 z-[100] flex max-h-screen w-[370px] flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col",
      className
    )}
    {...props} />
))
ToastViewport.displayName = ToastPrimitives.Viewport.displayName

const toastVariants = cva(
  cn("group !pointer-events-auto fixed top-[100px] right-0 w-full flex flex-col gap-2  overflow-hidden rounded-md p-6 pr-8 shadow-lg transition-all data-[state=open]:animate-in data-[state=closed]:animate-out",ui?.toastClassName),
  {
    variants: {
      variant: {
        default: cn("items-end w-[370px] border border-solid border-[--dialog-border-color] bg-[--toast] text-foreground  data-[state=open]:slide-in-from-right-full data-[state=closed]:slide-out-to-right-full",ui?.toastDefaultClassName),
        destructive:
          "destructive group border-destructive bg-destructive text-destructive-foreground",
        simple:cn("w-fit border border-solid border-[--toast-border-color] bg-[--toast] text-foreground p-4 fixed left-1/2 top-[100px] transform -translate-x-1/2 [&>[toast-close]]:hidden data-[state=open]:slide-in-from-top-full data-[state=open]:slide-in-from-left-1/2 data-[state=closed]:animate-out data-[state=closed]:slide-out-to-top data-[state=closed]:slide-out-to-left-1/2",ui?.toastSimpleClassName),
        orderSuccess:cn("items-end w-[370px] box-border border border-solid border-[--dialog-border-color] bg-[--toast] text-foreground  data-[state=open]:slide-in-from-right-full data-[state=closed]:slide-out-to-right-full",ui?.toastOrderSuccessClassName),
        orderFailure:cn("items-end w-[370px] box-border border border-solid border-[--dialog-border-color] bg-[--toast] text-foreground  data-[state=open]:slide-in-from-right-full data-[state=closed]:slide-out-to-right-full",ui?.toastOrderFailureClassName),
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

const Toast = React.forwardRef(({ className,variant, ...props }, ref) => {
  return (
    (<ToastPrimitives.Root
      ref={ref}
      className={cn(toastVariants({ variant }), className)}
      {...props} />)
  );
})
Toast.displayName = ToastPrimitives.Root.displayName

const ToastAction = React.forwardRef(({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cn(
      "btn btn-sm btn-primary",
      className
    )}
    {...props} />
))
ToastAction.displayName = ToastPrimitives.Action.displayName

const ToastClose = React.forwardRef(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn(
      "absolute right-2 top-2 rounded-md p-1 text-foreground text-opacity-50 opacity-100 transition-opacity hover:text-foreground focus:opacity-100 focus:outline-none focus:ring-2 group-[.destructive]:text-red-300 group-[.destructive]:hover:text-red-50 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600",
      ui?.toastCloseClassName,
      className
    )}
    toast-close=""
    {...props}>
    <X className="h-4 w-4" />
  </ToastPrimitives.Close>
))
ToastClose.displayName = ToastPrimitives.Close.displayName

const ToastTitle = React.forwardRef(({ className, ...props }, ref) => (
  <ToastPrimitives.Title ref={ref} className={cn("text-sm font-semibold inline-flex gap-2 items-center",ui?.toastTitleClassName, className)} {...props} />
))
ToastTitle.displayName = ToastPrimitives.Title.displayName

const ToastDescription = React.forwardRef(({ className,children, ...props }, ref) => (
  <ToastPrimitives.Description ref={ref} className={cn("text-sm opacity-90",ui?.toastDescriptionClassName, className)} {...props} >{children}</ToastPrimitives.Description>
))
ToastDescription.displayName = ToastPrimitives.Description.displayName

export { ToastProvider, ToastViewport, Toast, ToastTitle, ToastDescription, ToastClose, ToastAction };
