import Icon from "@/components/Icon";
import Spinner from "@/components/Spinner";
import Trans from "@/components/Trans";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { disclaimerAndSignStepEnum } from "@/constants/wallets";
import useTranslation from "@/hooks/useTranslation";
import gradientSpinner from "@/icons/gradient-spinner.svg";
import useGlobal from "@/stores/global";
import { deepCopy } from "@/utils/funcs";
import { checkRegisterCodeValid, checkUserConfig, checkUserExist, onboardSite, registerAccount } from "@/utils/onboarding";
import PropTypes from "prop-types";

import Tooltip from "@/components/Tooltip";
import { useToast } from "@/components/ui/use-toast";
import { CustomEvents } from "@/constants/customEvents";
import ui from "@/constants/ui";
import useOnboarding from "@/hooks/useOnboarding";
import useUser from "@/stores/user";
import { useBoolean, useEventListener } from "ahooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useAccount } from "wagmi";

const defaultCheckList = [
  {
    content: "I am aware that if I use leverage, my leverage may change after entering a position.",
    key: "disclaimer1",
    checked: true,
  },
  {
    content: "I understand the rules and risks associated with using cross-collateral as margin.",
    key: "disclaimer2",
    checked: true,
  },
  {
    content: "I understand the rules and risk that can arise with settlement of P&L, bankruptcies, insurance, and socialized losses.",
    key: "disclaimer3",
    checked: true,
  },
  {
    content: "I understand that my account may be partially or entirely liquidated if my positions breach any of the margin maintenance requirements.",
    key: "disclaimer4",
    checked: true,
  },
];

const StepSpinner = ({ loading, done, step }) => {
  return (
    <div className="size-[22px] relative bg-foreground bg-opacity-10 flex flex-row items-center justify-center rounded-full text-primary">
      {done ? (
        <svg className="size-full" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="11" cy="11" r="11" fill="white" fillOpacity="0.12" />
          <circle cx="11" cy="11" r="10.5" stroke="currentColor" />
          <g clipPath="url(#clip0_759_86590)">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.2287 12.9055L15.7341 6.40016L16.8655 7.53153L9.2287 15.1683L5.36319 11.3028L6.49456 10.1714L9.2287 12.9055Z" fill="currentColor" />
          </g>
          <defs>
            <clipPath id="clip0_759_86590">
              <rect width="16" height="16" fill="white" transform="translate(3 3)" />
            </clipPath>
          </defs>
        </svg>
      ) : null}
      {loading ? (
        <div className="absolute">
          <Spinner className="animate-spin size-full stroke-[4px]">
            <Icon icon={gradientSpinner} />
          </Spinner>
        </div>
      ) : null}

      {!done || loading ? <div className="text-[--foreground-tertiary] text-xs">{step}</div> : null}
    </div>
  );
};

StepSpinner.propTypes = {
  idle: PropTypes.bool,
  loading: PropTypes.bool,
  done: PropTypes.bool,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const DisclaimerCmp = () => {
  const { toast: Toast } = useToast();
  const { disclaimerAndSignStep, setGlobal, disclaimerAndSignVisible, onboardingClientAccountId } = useGlobal();
  const { address } = useAccount();
  const [ifUserExist, setIfUserExist] = useState(undefined);
  const { setUser, currentClientAccountId, isAlreadySigned, remember, account } = useUser();
  const alreadySigned = isAlreadySigned();
  const t = useTranslation();
  const disclaimer = t("disclaimer");
  const [inviteCodes, setInviteCodes] = useState(Array(6).fill(""));
  const inputRef = useRef([]);
  const [userInviteEnable, setUserInviteEnable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showErrorTips, setShowErrorTips] = useState(false);
  const [inviteCodeError, setInviteCodeError] = useState("");
  const [isGetConfig, setIsGetConfig] = useState(false);

  const handleCheckIsEnableInviteCode = async () => {
    try {
      setIsGetConfig(true);
      const checkRegisterRes = await checkUserConfig();
      setUserInviteEnable(checkRegisterRes?.data?.data?.registerCodeEnable);
    } catch (e) {
      Toast.show(e?.message, { type: "error" });
      setUserInviteEnable(false);
    } finally {
      setIsGetConfig(false);
    }
  };

  const handleCheckUserExist = async () => {
    let ifExists = undefined;
    try {
      if (alreadySigned) {
        ifExists = true;
      } else {
        const checkUserExistRes = await checkUserExist({ address });
        ifExists = checkUserExistRes?.data?.data?.isUserExist;
      }

      setIfUserExist(ifExists);
      if (ifExists === undefined) {
        // retry
        throw new Error("Network Error");
      }
      if (ifExists) {
        // 用户存在
        setGlobal({ disclaimerAndSignStep: disclaimerAndSignStepEnum.sign });
      } else {
        // 用户不存在
        setGlobal({ disclaimerAndSignStep: disclaimerAndSignStepEnum.disclaimer });
      }
      return ifExists;
    } catch (e) {
      Toast.show(e?.message, { type: "error" });
      // console.log("error", e);
    }
  };

  useEffect(() => {
    if (disclaimerAndSignVisible && address && disclaimerAndSignStep === disclaimerAndSignStepEnum.disclaimer) {
      handleCheckIsEnableInviteCode();
    }
  }, [disclaimerAndSignVisible, address, disclaimerAndSignStep]);

  useEffect(() => {
    if (
      disclaimerAndSignVisible &&
      address &&
      disclaimerAndSignStep !== disclaimerAndSignStepEnum.disclaimer &&
      disclaimerAndSignStep !== disclaimerAndSignStepEnum.sign &&
      disclaimerAndSignStep !== disclaimerAndSignStepEnum.inviteCode
    ) {
      handleCheckUserExist();
    }
  }, [disclaimerAndSignVisible, address, disclaimerAndSignStep]);

  // disclaimer
  const [checkList, setCheckList] = useState(defaultCheckList);
  const handleCheckList = (item, index, checked) => {
    setCheckList((old) => {
      const newValue = deepCopy(old);
      newValue[index] = {
        ...item,
        checked,
      };
      return newValue;
    });
  };

  const ifAllChecked = useMemo(() => !checkList.find((i) => !i.checked), [checkList]);

  const handleDisclaimerConfirm = () => {
    if (!ifAllChecked) return;
    if (isGetConfig) return;
    // setGlobal?.({ disclaimerAndSignStep: disclaimerAndSignStepEnum.sign });
    setGlobal?.({ disclaimerAndSignStep: userInviteEnable ? disclaimerAndSignStepEnum.inviteCode : disclaimerAndSignStepEnum.sign });
  };

  useEffect(() => {
    if (address && disclaimerAndSignVisible) {
      setSign1Step("idle");
      setSign2Step("idle");
    }
  }, [address, disclaimerAndSignVisible]);

  // sign
  const [signLoading, { setTrue: setSignLoadingTrue, setFalse: setSignLoadingFalse }] = useBoolean(false);
  const [sign1Step, setSign1Step] = useState("idle");
  const [sign2Step, setSign2Step] = useState("idle");
  const { onboarding } = useOnboarding();

  const onBeforeGenL2Key = () => {
    setSign1Step("loading");
  };
  const onAfterGenL2Key = () => {
    setSign1Step("done");
  };
  const onBeforeGenApiKey = () => {
    setSign1Step("done");
    setSign2Step("loading");
  };
  const onAfterGenApiKey = () => {
    setSign2Step("done");
  };

  const handleSignConfirm = async () => {
    try {
      setSignLoadingTrue();
      const clientAccountId = onboardingClientAccountId || currentClientAccountId;
      const { appEnvInfo, userKeys, currentActiveUserKey, apiKeySignature, expiredTime } = await onboarding({
        clientAccountId,
        onBeforeGenL2Key,
        onAfterGenL2Key,
        onBeforeGenApiKey,
        onAfterGenApiKey,
      });
      const currentUserKeys = currentActiveUserKey?.[address?.toLowerCase()];
      const l2Key = currentUserKeys?.l2Key?.publicKey;
      const l2KeyYCoordinate = currentUserKeys?.l2Key?.publicKeyYCoordinate;
      // const signature = l2KeySignature;

      await onboardSite({
        address,
        appOnlySignOn: appEnvInfo?.appOnlySignOn,
        param: "",
        signature: apiKeySignature,
        l2Key,
        l2KeyYCoordinate,
        clientAccountId,
        expiredTime,
        registerCode: inviteCodes.join(""),
      });
      setUser({ keys: userKeys });
      let hasRegistered = false;
      // 考虑子账号注册的场景
      if ((!ifUserExist && ifUserExist !== undefined) || (!account?.find((a) => a.clientAccountId == onboardingClientAccountId) && clientAccountId != "main")) {
        await registerAccount({ l2Key, l2KeyYCoordinate, clientAccountId });
        hasRegistered = true;
      }

      setSignLoadingFalse();
      // 触发事件
      window.dispatchEvent(new CustomEvent(CustomEvents["account-onboard"], { detail: { clientAccountId, hasRegistered } }));
      setGlobal({ disclaimerAndSignVisible: false, disclaimerAndSignStep: disclaimerAndSignStepEnum.unknown });
    } catch (e) {
      setSignLoadingFalse();

      if (e?.stack) {
        const errorPattern = /Error: ([^:]+): ([^\n]+)/;
        const detailsPattern = /Details: ([^\n]+)/;
        const versionPattern = /Version: ([^\n]+)/;

        const errorMatch = e?.stack.match(errorPattern);
        const detailsMatch = e?.stack.match(detailsPattern);
        const versionMatch = e?.stack.match(versionPattern);

        const errorObject = {
          error: errorMatch ? errorMatch[2] : "",
          details: detailsMatch ? detailsMatch[1] : "",
          version: versionMatch ? versionMatch[1] : "",
        };
        Toast.show(errorObject?.error || e?.message, { type: "error" });
      } else {
        // eslint-disable-next-line no-console
        console.log("error", e);
        Toast.show(e?.message, { type: "error" });
      }
    }
  };
  const isComplete = useMemo(() => {
    return inviteCodes.every((code) => code !== "");
  }, [inviteCodes]);
  // Invitation code input event
  const handleChange = (e, index) => {
    setShowErrorTips(false);
    const value = e.target.value;
    const newCodes = [...inviteCodes];
    newCodes[index] = value;
    setInviteCodes(newCodes);
    // Automatically focus on the next input
    if (value !== "" && index < 5) {
      inputRef.current[index + 1].focus();
    }
    if (!isSubmitting && newCodes.every((code) => code !== "")) {
      handleSubmitInviteCode(newCodes);
    }
  };

  // paste event
  const handlePaste = (e) => {
    setShowErrorTips(false);
    e.preventDefault();
    const pasteData = e.clipboardData.getData("Text");
    const pasteCodes = pasteData.slice(0, 6).split("");
    const newCodes = [...inviteCodes];
    pasteCodes.forEach((char, idx) => {
      newCodes[idx] = char;
    });
    setInviteCodes(newCodes);
    const nextIndex = pasteCodes.length < 6 ? pasteCodes.length : 5;
    if (inputRef.current[nextIndex]) {
      inputRef.current[nextIndex].focus();
    }
    if (!isSubmitting && newCodes.every((code) => code !== "")) {
      handleSubmitInviteCode(newCodes);
    }
  };

  // keyboard delete key event
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      setShowErrorTips(false);
      if (inviteCodes[index] === "") {
        if (index > 0) {
          inputRef.current[index - 1].focus();
        }
      } else {
        const newCodes = [...inviteCodes];
        newCodes[index] = "";
        setInviteCodes(newCodes);
      }
    }
  };

  function getErrorMessage(status) {
    switch (status) {
      case "IN_VALID":
        return "invite_code_error";
      case "HAS_USED":
        return "invite_code_exist";
      default:
        return "";
    }
  }

  // submit invite code
  const handleSubmitInviteCode = async (currentCodes) => {
    if (isSubmitting) return;
    const verificationCode = currentCodes.join("");
    setIsSubmitting(true);
    try {
      const checkInvalidateRes = await checkRegisterCodeValid({ address, registerCode: verificationCode });
      if (checkInvalidateRes?.data?.data?.valid) {
        setGlobal?.({ disclaimerAndSignStep: disclaimerAndSignStepEnum.sign });
        setInviteCodeError("");
      } else {
        setShowErrorTips(true);

        const status = checkInvalidateRes?.data?.data?.status;
        const errorMessage = getErrorMessage(status);

        setInviteCodeError(errorMessage);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  // 如果未指定step，则自动跑请求检查全流程

  // if (alreadySigned) {
  //   return (
  //     <div className="flex flex-col gap-2 break-all">
  //       <div>keys: {JSON.stringify(keys?.[address?.toLowerCase()]?.[currentClientAccountId])}</div>
  //       <div>accountId: {currentClientAccountId}</div>
  //     </div>
  //   );
  // }
  // checkUserExist -> disclaimer/sign -> 注册/登录
  // 免责弹窗
  if (disclaimerAndSignStep === disclaimerAndSignStepEnum.disclaimer) {
    return (
      <div className="flex flex-col gap-6 items-center justify-center">
        <div className="flex flex-col gap-4">
          <div className="text-sm font-normal text-foreground [&_a]:text-primary" dangerouslySetInnerHTML={{ __html: disclaimer }}></div>
          <div className="flex flex-col gap-4 p-4 bg-black/30 border border-[--line-gray] border-solid rounded-xl">
            {checkList?.map((i, index) => {
              return (
                <div key={index} className="flex flex-row items-center gap-2 text-[--foreground-tertiary] text-sm font-normal">
                  <input
                    id={i.key}
                    name={i.key}
                    type="checkbox"
                    onChange={(e) => {
                      handleCheckList(i, index, e.target.checked);
                    }}
                    checked={i?.checked}
                    className="checkbox checkbox-xs checkbox-primary"
                  />
                  <label htmlFor={i.key} className="cursor-pointer">
                    <Trans i18nKey={i.key}>{i.content}</Trans>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <button onClick={handleDisclaimerConfirm} disabled={!ifAllChecked} className={`w-full h-12 btn btn-primary text-base font-bold`}>
          <Trans i18nKey="agreeAndContinue">Agree and Continue</Trans>
          {isGetConfig && <span className="loading loading-xs loading-spin"></span>}
        </button>
      </div>
    );
  }
  // 输入验证码
  if (disclaimerAndSignStep === disclaimerAndSignStepEnum.inviteCode) {
    return (
      <div className="flex flex-col gap-y-6">
        <p className="text-[14px] leading-5 text-foreground-secondary">
          <Trans i18nKey={"invite_sub_title"}>The product is in beta testing. You need an invitation code to experience the full product.</Trans>
        </p>
        <div className="flex flex-col gap-y-3">
          <span className="text-[16px] leading-6 text-foreground">
            <Trans i18nKey="pls_enter_invite">Enter invitation code</Trans>
          </span>

          <div className="flex gap-x-[10px]">
            {inviteCodes.map((item, index) => {
              return (
                <input
                  key={`invite-code-input-${index}`}
                  type="text"
                  ref={(el) => (inputRef.current[index] = el)}
                  maxLength={1}
                  value={item}
                  className="p-4 h-[60px] w-[60px] rounded-xl bg-bg-secondary text-center text-[28px] leading-8 text-foreground-secondary focus:outline-none focus:border focus:!border-brand"
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={(e) => handlePaste(e)}
                  autoFocus={index === 0}
                />
              );
            })}
          </div>
          {showErrorTips && <span className="text-[14px] leading-5 text-error">{t(inviteCodeError)}</span>}
        </div>
        <button onClick={() => handleSubmitInviteCode(inviteCodes)} disabled={!isComplete} className={`w-full h-12 btn btn-primary text-base font-bold ${isSubmitting ? "pointer-events-none" : ""}`}>
          <Trans i18nKey="invite_next_btn">Next</Trans>
          {isSubmitting && <span className="loading loading-xs loading-spin"></span>}
        </button>
      </div>
    );
  }
  // 连接钱包
  if (disclaimerAndSignStep === disclaimerAndSignStepEnum.sign) {
    return (
      <div className="flex flex-col gap-4 items-start w-full">
        <div className="text-xs mt-4 text-foreground">
          <Trans i18nKey="disclaimerTips">You will receive 2 signature requests. Signing is free and will not trigger any transaction(s).</Trans>
        </div>
        <div className="w-full p-4 rounded-2xl bg-bg-secondary flex flex-col gap-4">
          <div className="w-full flex flex-row gap-2.5">
            <div className="">
              <StepSpinner loading={sign1Step === "loading"} done={sign1Step === "done"} step={1} />
            </div>
            <div className="r flex flex-col gap-2  text-sm">
              <div className="font-bold text-foreground">
                <Trans i18nKey="verifyOwnership">Verify ownership</Trans>
              </div>
              <div className=" text-foreground-tertiary text-xs">
                <Trans i18nKey="confirmWalletOwner">Confirm you are the owner of this wallet</Trans>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-row gap-2.5">
            <div className="">
              <StepSpinner loading={sign2Step === "loading"} done={sign2Step === "done"} step={2} />
            </div>
            <div className=" flex flex-col gap-2 text-sm">
              <div className="font-bold text-foreground">
                <Trans i18nKey="enableTrading">Enable trading</Trans>
              </div>
              <div className="text-foreground-tertiary text-xs">
                <Trans i18nKey="enableTradingConfirm">Enable secure access to our API for lightning quick trading</Trans>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full justify-between items-center flex flex-row">
          <Tooltip title={t("rememberMeDesc")} placement="top">
            <label htmlFor="remember" className={twMerge(ui?.helperClassName, "text-sm")}>
              <Trans i18nKey="rememberMe">Remember me</Trans>
            </label>
          </Tooltip>
          <span className="inline-flex items-center cursor-pointer">
            <input id="remember" type="checkbox" value="" className="sr-only peer" onChange={(e) => setUser({ remember: e.target.checked })} checked={remember} />
            <label
              htmlFor="remember"
              className="relative w-7 h-4 cursor-pointer bg-bg-tertiary rounded-full peer peer-checked:after:left-4 after:content-[''] after:absolute after:top-1 after:left-1 after:bg-background after:rounded-full after:h-2 after:w-2 after:transition-all  peer-checked:after:bg-brand"
            ></label>
          </span>
        </div>
        <button
          onClick={handleSignConfirm}
          disabled={!ifAllChecked}
          className={`w-full h-12 mt-2 btn btn-primary flex items-center gap-2 text-base font-bold ${signLoading ? "pointer-events-none" : ""}`}
        >
          <Trans i18nKey="sendRequests">Send requests</Trans>
          {signLoading && <span className="loading loading-xs loading-spin"></span>}
        </button>
      </div>
    );
  }
  return (
    <div className="py-6 flex flex-row items-center justify-center">
      <Spinner className="size-12" />
    </div>
  );
};

const DisclaimerAndSignDialog = () => {
  const multiLanguage = useTranslation();
  const [title, setTitle] = useState("connectWallet");
  const { disclaimerAndSignVisible, setGlobal, onboardingClientAccountId, disclaimerAndSignStep } = useGlobal();

  useEffect(() => {
    if (disclaimerAndSignStep !== disclaimerAndSignStepEnum.inviteCode) {
      setTitle("connectWallet");
    } else {
      setTitle("invite_title");
    }
  }, [disclaimerAndSignStep]);

  // 窗口关闭清空 onboardingClientAccountId
  const handleChanges = (v) => {
    setGlobal?.({ disclaimerAndSignVisible: v, onboardingClientAccountId: v ? onboardingClientAccountId : "" });
    if (!v) {
      setGlobal({ disclaimerAndSignStep: disclaimerAndSignStepEnum.unknown });
    }
  };

  // 添加事件触发
  useEventListener(CustomEvents["open-invite-dialog"], () => {
    setGlobal({ disclaimerAndSignVisible: true, disclaimerAndSignStep: disclaimerAndSignStepEnum.inviteCode });
    setTitle("invite_title");
  });

  useEventListener(CustomEvents["close-invite-dialog"], () => {
    setGlobal({ disclaimerAndSignVisible: false, disclaimerAndSignStep: disclaimerAndSignStepEnum.unknown });
  });

  useEventListener(CustomEvents["open-disclaimer-dialog"], () => {
    setGlobal({ disclaimerAndSignVisible: true, disclaimerAndSignStep: disclaimerAndSignStepEnum.disclaimer });
    setTitle("disclaimer");
  });

  useEventListener(CustomEvents["close-disclaimer-dialog"], () => {
    setGlobal({ disclaimerAndSignVisible: false, disclaimerAndSignStep: disclaimerAndSignStepEnum.unknown });
  });

  useEventListener(CustomEvents["open-sign-dialog"], () => {
    setGlobal({ disclaimerAndSignVisible: true, disclaimerAndSignStep: disclaimerAndSignStepEnum.sign });
    setTitle("connectWallet");
  });

  useEventListener(CustomEvents["close-sign-dialog"], () => {
    setGlobal({ disclaimerAndSignVisible: false, disclaimerAndSignStep: disclaimerAndSignStepEnum.unknown });
  });

  useEventListener(CustomEvents["set-disclaimer-sign-dialog"], (e) => {
    setGlobal(e?.detail);
  });

  return (
    <Dialog open={disclaimerAndSignVisible} onOpenChange={handleChanges}>
      <DialogContent
        className="max-w-[480px] "
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle>{multiLanguage(title)}</DialogTitle>
        </DialogHeader>
        <DisclaimerCmp />
      </DialogContent>
    </Dialog>
  );
};

DisclaimerAndSignDialog.propTypes = {};

export default DisclaimerAndSignDialog;
