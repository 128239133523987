import { Toaster } from "@/components/ui/toaster";
import useIsFromAppRedirect from "@/hooks/useIsFromAppRedirect";
import Bootstrap from "@/modules/layout/components/Bootstrap";
import DialogWsConnectError from "@/modules/layout/components/DialogWsConnectError";
import DisclaimerAndSignDialog from "@/modules/layout/components/DisclaimerAndSignDialog";
import Footer from "@/modules/layout/components/Footer";
import Header from "@/modules/layout/components/Header";
import UserUpdater from "@/modules/layout/components/UserUpdater";
import QuoteDataHandler from "@/modules/trade/components/QuoteDataHandler";
import WalletConnectDialog from "@/modules/walletConnect/components/WalletConnectDialog";
import { Outlet, useLocation } from "react-router";
import { twMerge } from "tailwind-merge";
import WsStatusToaster from "./modules/layout/components/WsStatusToaster";

function Layout() {
  const location = useLocation();
  const paths = ["trade", "dashboard", "faucet", "market"];
  const isApp = useIsFromAppRedirect();
  // TODO: temp solution for /download page
  const isHideHeaderFooter = location.pathname.includes("download");

  let wrapperClassName = "";
  const headerClassName = "z-50";
  let footerClassName = "";

  if (!paths.some((path) => location.pathname.includes(path))) {
    wrapperClassName = "bg-bg-primary";
    footerClassName = "!rounded-t-none";
  }

  return (
    <Bootstrap>
      <QuoteDataHandler />
      <UserUpdater />
      <WsStatusToaster />
      <WalletConnectDialog />
      <DisclaimerAndSignDialog />
      <DialogWsConnectError />
      <div className={`${wrapperClassName} flex-1 flex flex-col`}>
        {!isApp && <Header className={twMerge(headerClassName, `air:flex ${isHideHeaderFooter ? "hidden" : ""}`)} />}
        <Outlet />
        {!isApp && <Footer className={twMerge(footerClassName, `air:flex ${isHideHeaderFooter ? "hidden" : ""}`)} />}
      </div>
      <Toaster />
    </Bootstrap>
  );
}

export default Layout;
