import React from "react";
import PropTypes from "prop-types";

const Icon = ({ icon, ...rest }) => {
  return (
    <svg viewBox={icon.viewBox} {...rest}>
      <use xlinkHref={`#${icon.id}`} />
    </svg>
  );
};
Icon.propTypes = {
  icon: PropTypes.object.isRequired,
};

export default Icon;
