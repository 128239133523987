import axios from "@/services/request";

export const UserApi = {
  getUser: "/v1/private/user/getUserInfo",
  logoutUser: "/v1/private/user/logout",
};

export const getUserInfo = () => {
  return axios.get(UserApi.getUser);
};

export const logoutUser = () => {
  return axios.post(UserApi.logoutUser);
};
