/**
 * alternative of react-i18next <Trans /> Component
 */
import React from "react";
import PropTypes from "prop-types";
import useTranslation from "@/hooks/useTranslation";

const Trans = ({ values = {}, i18nKey = "", children }) => {
  const t = useTranslation();
  // "確定是否<1>{{triggerPrice}}</1>觸發<3>{{size}}{{symbol}}</3>市價止盈單？"
  const translated = t(i18nKey, values);
  const segments = translated.split(/<([\d]+)>[\s\S]*?<\/\1>/g);

  // i18nKey == translated key 不存在，直接返回 children
  return <>{i18nKey == translated ? children : segments.map((s, idx) => <React.Fragment key={idx}>{children?.[s] || s}</React.Fragment>)}</>;
};

Trans.propTypes = {
  children: PropTypes.any,
  values: PropTypes.object,
  i18nKey: PropTypes.string,
};

export default Trans;
