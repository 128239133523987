import { useToast } from "@/components/ui/use-toast";
import { CustomEvents } from "@/constants/customEvents";
import useTranslation from "@/hooks/useTranslation";
import orderToast from "@/modules/layout/components/orderToast";
import useInterface from "@/stores/interface";
import useSymbol from "@/stores/symbol";
import useUser from "@/stores/user";
import { useDebounceFn, useEventListener, useSessionStorageState } from "ahooks";

const WsStatusToaster = () => {
  const multiLanguage = useTranslation();
  const { toast } = useToast();
  const { metadata } = useInterface();
  const { getSymbols } = useSymbol();
  const symbols = getSymbols();
  const { deposit, fastWithdraw, order } = useUser();
  const [toastedOrderIds, setToastedOrderIds] = useSessionStorageState("toastedOrderIds", { defaultValue: [] }); // 本地存储已提示的订单 id
  const [toastedDepositIds, setToastedDepositIds] = useSessionStorageState("toastedDepositIds", { defaultValue: [] });
  const [toastedWithdrawIds, setToastedWithdrawIds] = useSessionStorageState("toastedWithdrawIds", { defaultValue: [] });

  const { run: toastStatus } = useDebounceFn(
    () => {
      // order status changes
      const needToastOrders = order
        ?.filter((i) => {
          return ["CANCELED", "FILLED"].includes(i?.status); // 终态
        })
        // 通过 private ws 推送的订单更新才提示
        // 进入终态订单只提示一次
        .filter((i) => i.from == "ORDER_UPDATE" && !toastedOrderIds.includes(i.id));
      needToastOrders?.forEach((order) => {
        const contract = symbols?.find((j) => j.contractId === order.contractId);
        orderToast({ order, contract, toast });
      });
      setToastedOrderIds((prev) => [...prev, ...(needToastOrders?.map((i) => i.id) || [])]);

      // deposit status changes
      const needToastDeposits = deposit
        ?.filter((i) => {
          return ["SUCCESS_CENSOR_SUCCESS"].includes(i?.status); // 终态
        })
        .filter((i) => !toastedDepositIds.includes(i.id));
      needToastDeposits?.forEach((deposit) => {
        const tokenName = metadata?.coinList?.find((j) => j.coinId === deposit.coinId)?.coinName;
        toast.show({
          type: "success",
          title: multiLanguage("depositSuccessTitle"),
          description: `${multiLanguage("depositSuccessInfo", {
            coin: tokenName,
            value: deposit.amount,
          })}`,
        });
      });
      setToastedDepositIds((prev) => [...prev, ...(needToastDeposits?.map((i) => i.id) || [])]);

      // withdraw status changes
      // 目前通用的提现的推送不准确，暂时不提示
      // const needToastWithdraws = [...withdraw]
      //   ?.filter((i) => {
      //     return ["SUCCESS_CENSOR_SUCCESS"].includes(i?.status);
      //   })
      //   .filter((i) => !toastedWithdrawIds.includes(i.id));

      // 快速提现提示
      const needToastWithdraws = [...fastWithdraw]
        ?.filter((i) => {
          return ["FAST_WITHDRAW_PENDING_CENSORING_CONFIRMING", "FAST_WITHDRAW_PENDING_L2_APPROVING"].includes(i?.status);
        })
        .filter((i) => !toastedWithdrawIds.includes(i.id));

      needToastWithdraws?.forEach((withdraw) => {
        const tokenName = metadata?.coinList?.find((j) => j.coinId === withdraw.coinId)?.coinName;
        toast.show({
          type: "success",
          title: multiLanguage("withdrawSuccessTitle"),
          description: `${multiLanguage("withdrawSuccessInfo", {
            coin: tokenName,
            value: withdraw.conditionFactAmount || withdraw.amount,
          })}`,
        });
      });

      setToastedWithdrawIds((prev) => [...prev, ...(needToastWithdraws?.map((i) => i.id) || [])]);
    },
    { wait: 200 },
  );
  // 监听 ws 推送订单，并提示
  useEventListener(CustomEvents["ws-message"], toastStatus, { target: document });

  // 清算提示
  useEventListener(
    CustomEvents["ws-message"],
    (e) => {
      if (e.detail?.type == "trade-event" && e.detail?.content?.event == "START_LIQUIDATING") {
        toast.show({
          type: "error",
          title: multiLanguage("liquidationTitle"),
          description: multiLanguage("liquidationInfo"),
        });
      }
    },
    { target: document },
  );

  return <></>;
};

export default WsStatusToaster;
