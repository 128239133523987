import { CookieKey } from "@/constants/cookies";
import useGlobal from "@/stores/global";
import useUser from "@/stores/user";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function useIsFromAppRedirect() {
  const [searchParams] = useSearchParams();
  const { lang, setGlobal } = useGlobal();
  const { currentActiveAccount, setUser } = useUser();
  const newLang = searchParams.get("appLang") || lang;
  const [isApp, setIsApp] = useState(Cookies.get(CookieKey.IS_APP) === "1") || false;

  useEffect(() => {
    setGlobal({ lang: newLang });
    setUser({
      currentActiveAccount: {
        ...currentActiveAccount,
        user: {
          ...currentActiveAccount?.user,
          language: newLang,
        },
      },
    });

    if (isApp) {
      setIsApp(true);
    } else {
      setIsApp(false);
      Cookies.set(CookieKey.IS_APP, "0");
    }
  }, [newLang, searchParams, setGlobal]);

  return isApp;
}
