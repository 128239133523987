/* eslint-disable */
import ui from "@/constants/ui";
import BigNumber from "bignumber.js";
/**
 *
 * @param obj
 * @returns {any}
 */
export function deepCopy(obj) {
  let results = typeof obj === "object" ? (Array.isArray(obj) ? [] : obj === null ? null : {}) : obj;
  if (typeof results === "object" && results !== null) {
    for (let item in obj) {
      if (obj && obj.hasOwnProperty(item)) {
        results[item] = deepCopy(obj[item]);
      }
    }
  }
  return results;
}

export const defaultTimeFormat = ui?.defaultTimeFormat || "YYYY-mm-dd HH:MM:SS";
export const defaultDateFormat = ui?.defaultDateFormat || "YYYY-mm-dd";
/**
 *
 * @param millisecond
 * @param formatter
 * @returns {string}
 */
export function formatDateTime(millisecond, formatter = defaultTimeFormat) {
  const date = new Date(parseInt(millisecond));
  const opt = {
    "Y+": date.getFullYear().toString(),
    "m+": (date.getMonth() + 1).toString(),
    "d+": date.getDate().toString(),
    "H+": date.getHours().toString(),
    "M+": date.getMinutes().toString(),
    "S+": date.getSeconds().toString(), // 秒
  };
  for (let k in opt) {
    let reg = new RegExp("(" + k + ")").exec(formatter);
    if (reg) {
      formatter = formatter.replace(reg[1], reg[1].length == 1 ? opt[k] : opt[k].padStart(reg[1].length, "0"));
    }
  }
  return formatter;
}
export function printTraceless(...arg) {
  //@ts-ignore
  setTimeout(console.log.bind(this, ...arguments), 0);
}
export function formatSeconds(seconds) {
  seconds = Number(seconds);
  const tempObj = {
    day: "00",
    hour: "00",
    minute: "00",
    second: "00",
  };
  if (seconds && seconds > 0) {
    const day = Math.floor(seconds / 86400);
    tempObj.day = (day > 9 ? "" : "0") + day;
    seconds = seconds % 86400;
    const hour = Math.floor(seconds / 3600);
    tempObj.hour = (hour > 9 ? "" : "0") + hour;
    seconds = seconds % 3600;
    const minute = Math.floor(seconds / 60);
    tempObj.minute = (minute > 9 ? "" : "0") + minute;
    const second = seconds % 60;
    tempObj.second = (second > 9 ? "" : "0") + second;
  }
  return tempObj;
}

/**
 * diff two timestamp in xx D xxH xxM xxS
 * @param {string} start "1711368706111"
 * @param {string} end "1711368854039"
 * @param {string} format default "{D}D {H}H {M}M"
 * @returns {string} formatted diff string
 */
export function diffDateTime(start, end, format = "{D}D {H}H {M}M") {
  // diff two timestamp in xx D xxH xxM xxS
  const diff = end - start;
  const days = Math.floor(diff / (24 * 3600 * 1000));
  const leave1 = diff % (24 * 3600 * 1000);
  const hours = Math.floor(leave1 / (3600 * 1000));
  const leave2 = leave1 % (3600 * 1000);
  const minutes = Math.floor(leave2 / (60 * 1000));
  const seconds = Math.floor((leave2 % (60 * 1000)) / 1000);

  return format.replace("{D}", days).replace("{H}", hours).replace("{M}", minutes).replace("{S}", seconds);
}

function compare(key, type) {
  return function sortGen(m, n) {
    const a = isNaN(Number(m[key])) ? m[key]?.toUpperCase() : Number(m[key]);
    const b = isNaN(Number(n[key])) ? n[key]?.toUpperCase() : Number(n[key]);
    if (a < b) {
      return type === "up" ? -1 : 1;
    }
    if (a > b) {
      return type === "up" ? 1 : -1;
    }
    return 0;
  };
}
/**
 *
 * @param list
 * @param key
 * @param type
 * @returns {any}
 */
export function sortObjectArrayByKey(list, key, type) {
  const tempList = deepCopy(list);
  return type === "" ? tempList : tempList.sort(compare(key, type || "up"));
}
export function findIndexByKey(list, key, value) {
  let tempIdx = -1;
  list.some((item, idx) => {
    if (item[key] === value) {
      tempIdx = idx;
      return true;
    }
  });
  return tempIdx;
}
// 数字处理
/**********************************************************************/
/**
 * 科学计数转普通数字
 * @param num
 * @returns {string}
 */
export function toNonExponential(num) {
  const tempResult = Number(num);
  if (isNaN(tempResult)) {
    return String(num);
  }
  const matches = tempResult.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
  console.log(num, tempResult, matches);
  return tempResult.toFixed(Math.max(0, (matches ? matches[1] : "")?.length - Number(matches ? matches[2] : 0)));
}
/**
 * 获取数值、数字字符串 精度
 * "0.001" => 3 "2000" => 0
 */
export function getNumberPrecision(num) {
  const valStrList = String(num).split(".");
  return valStrList.length <= 2 ? (valStrList[1] ? valStrList[1].length : 0) : 2;
}
/**
 * Round by tickSize
 * @param num
 * @param tickSize
 * @param isFloor 是否向下取值，用于计算最大值时，向下取数据、防止溢出
 * @returns {number}
 *
 * toTickSizeNumber(1.58, 0.05) -> 1.60
 * toTickSizeNumber(1.54, 0.05) -> 1.55
 */
export function toTickSizeRoundString(num, tickSize, isFloor) {
  const tempResult = Number(num);
  if (isNaN(tempResult) || tickSize <= 0) {
    return String(num);
  }
  let result;
  const tickSizeNum = Number(tickSize);
  if (tickSizeNum >= 1) {
    result = (Math[isFloor ? "floor" : "round"](tempResult / tickSizeNum) * tickSizeNum).toFixed(0);
  } else {
    const precision = String(tickSize).length - 2;
    result = bigNumberMultiply(Math[isFloor ? "floor" : "round"](bigNumberMultiply(tempResult, 1 / tickSizeNum)), tickSizeNum).toFixed(precision);
  }
  return Number(result) === 0 ? (tempResult !== 0 ? String(tickSize) : result) : result;
}
/**
 * 百分比处理
 * @param num
 * @param precision 默认 2 位
 * @param roundingMode like BigDecimal.ROUND_UP 默认是四舍五入
 * @returns {number | string}
 */
export function toPercentString(num, precision, roundingMode) {
  const tempResult = Number(num);
  const valPrecision = precision === undefined ? 2 : precision;
  return isNaN(tempResult) ? `${(0).toFixed(valPrecision)}%` : `${BigNumber(tempResult).multipliedBy(100).toFixed(valPrecision, roundingMode)}%`;
}
/**
 * 普通小数精度处理 截取有效位
 * toPrecisionString(0.001, 2) -> '0.00'
 * toPrecisionString(0.045, 2) -> '0.04'
 * toPrecisionString(0.045, 6) -> '0.045000'
 * toPrecisionString(0.001, 2, 'withMinVal') -> '0.01'
 * toPrecisionString(0.01, 3) -> '0.01'
 * toPrecisionString(0.000, 3) -> '0.000'
 * @param num
 * @param precision
 * @param withMinVal 是否需要最小值
 * @returns {string}
 */
export function toPrecisionString(num, precision = 2, withMinVal) {
  const tempResult = Number(num);
  const valPrecision = precision === undefined ? 2 : precision;

  if (num === undefined || isNaN(num)) return (0).toFixed(valPrecision);

  const scaleNum = Math.pow(10, Math.ceil(valPrecision));
  let result = String(isNaN(tempResult) ? num : (Math.floor(bigNumberMultiply(tempResult, scaleNum)) / scaleNum).toFixed(valPrecision));

  // Remove trailing zeros for cases like 0.01 when precision is 3 (to prevent '0.010')
  // Ignore 0.000 do not remove trailing zeros
  if (BigNumber(result).gt(0) && !withMinVal && result.includes(".")) {
    result = result.replace(/(\.\d*?[1-9])0+$/g, "$1").replace(/\.0+$/, ""); // Removes unnecessary zeros
  }

  return Boolean(withMinVal) && Number(result) === 0 ? String(Math.pow(10, -precision)) : result;
}
/**
 * 向下 或 向上取精度
 * toPrecisionStringWithType(0.001, 2, 'ceil') -> '0.01'
 * toPrecisionStringWithType(0.045, 2) -> '0.04'
 * toPrecisionStringWithType(0.045, 6) -> '0.045000'
 * @param num
 * @param precision
 * @param type
 */
export function toPrecisionStringWithType(num, precision = 2, type = "floor") {
  const tempResult = Number(num);
  const valPrecision = precision === undefined ? 2 : precision;
  if (isNaN(tempResult)) {
    return (0).toFixed(valPrecision);
  } else {
    const scaleNum = Math.pow(10, valPrecision);
    return (Math[type](bigNumberMultiply(tempResult, scaleNum)) / scaleNum).toFixed(valPrecision);
  }
}
/**
 * 千分化 小数点截取
 * toThousandString(0.004, 2) -> 0.00
 * toThousandString(0.004, 2, 'withMinVal') -> 0.01
 * toThousandString(10001.123, 6) -> 10,001.123000
 * toThousandString(10001.1234567, 6) -> 10,001.123456
 * @param num
 * @param precision
 * @param withMinVal
 * @returns {number | string}
 */
export function toThousandString(num, precision, withMinVal) {
  const tempResult = Number(num);
  const valPrecision = precision === undefined ? 2 : precision;
  if (isNaN(tempResult)) {
    return (0).toFixed(valPrecision);
  }
  const scaleNum = Math.pow(10, valPrecision);
  const tempStrList = (Math.floor(bigNumberMultiply(tempResult, scaleNum)) / scaleNum).toFixed(valPrecision).split(".");
  const result = tempStrList.length === 2 ? `${Number(tempStrList[0]).toLocaleString()}.${tempStrList[1]}` : Number(tempStrList[0]).toLocaleString();
  return Boolean(withMinVal) && Number(result.replace(/\\,/g, "")) === 0 ? String(Math.pow(10, -valPrecision)) : result;
}
/**
 * 大数据 添加B M K
 * @param num
 * @param precision
 * @returns {number | string}
 */
export function toLargeUnitString(num, precision) {
  const tempResult = Number(num);
  if (isNaN(tempResult)) {
    return "";
  }
  const tempPrecision = precision === undefined ? 2 : precision;
  let result;
  switch (true) {
    case tempResult > 1000000000: // B
      result = `${(tempResult / 1000000000).toFixed(tempPrecision)}B`;
      break;
    case tempResult > 1000000: // M
      result = `${(tempResult / 1000000).toFixed(tempPrecision)}M`;
      break;
    case tempResult > 1000: // K
      result = `${(tempResult / 1000).toFixed(tempPrecision)}K`;
      break;
    default:
      result = tempResult.toFixed(tempPrecision);
  }
  return result;
}
/**********************************************************************/
// 带精度计算 相乘
export function bigNumberMultiply(...args) {
  switch (true) {
    case !args.length:
      return 0;
    case args.length === 1:
      return new BigNumber(args[0]).toNumber();
    default:
      const lastArgs = args.slice(1);
      return lastArgs
        .reduce((previousValue, currentValue) => {
          // if (isNaN(currentValue)) {
          //   console.log(`${args} has isNaN number`);
          // }
          return previousValue.multipliedBy(isNaN(currentValue) ? 1 : currentValue);
        }, new BigNumber(args[0]))
        .toNumber();
  }
}
// 带精度计算 相加
export function bigNumberPlus(...args) {
  switch (true) {
    case !args.length:
      return 0;
    case args.length === 1:
      return new BigNumber(args[0]).toNumber();
    default:
      const lastArgs = args.slice(1);
      return lastArgs
        .reduce((previousValue, currentValue) => {
          // if (isNaN(currentValue)) {
          //   console.log(`${args} has isNaN number`);
          // }
          return previousValue.plus(isNaN(currentValue) ? 0 : currentValue);
        }, new BigNumber(args[0]))
        .toNumber();
  }
}
// 带精度计算 取余
export function bigNumberModulo(numA, numB) {
  const father = new BigNumber(numA);
  return father.modulo(numB).toNumber();
}
/*********************************************************************/

// 金额添加$，同时千分位化
export function armWith$(val, precision) {
  const num = Number(val);
  if (isNaN(num)) {
    return String(val);
  }
  return `${num >= 0 ? "" : "-"}$${toThousandString(Math.abs(num), precision === undefined ? 2 : precision)}`;
}
// 获取小数位，分割 字符小数点
export function getPrecision(num) {
  const val = Number(num);
  if (isNaN(val)) {
    return 0;
  }
  const strList = String(num).split(".");
  return strList.length === 2 ? strList[1].length : 0;
}
export function capitalizeFirstLetter(txt) {
  if (typeof txt === "string" && txt.length) {
    return txt.charAt(0).toUpperCase() + txt.slice(1);
  }
  return txt;
}

/**
 * 字符串截短
 * @param str 原字符串
 * @param n 保留位数
 */
export const shortStr = (str, n) => {
  if (!str || Object.prototype.toString.call(str) != "[object String]") {
    return "";
  }
  if (Number.isNaN(Number(n)) || Number(n) === 0) {
    return str;
  }
  if (str.length <= n) {
    return str;
  }
  const len = Math.ceil(n / 2);
  let pre = str.slice(0, len);
  let next = str.slice(str.length - len, str.length);
  return pre + "..." + next;
};
