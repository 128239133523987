export const URLKey = {
  SOCIAL_X: "https://x.com/tetadexHQ",
  SOCIAL_YOUTUBE: "#",
  SOCIAL_TELEGRAM: "https://t.me/tetadex",
  SOCIAL_DISCORD: "https://discord.gg/zc9vHYkcjN",

  COINMARKET: "#",

  GITBOOK_DOCS: "https://tetadex.gitbook.io/docs",
  GUIDES: "https://tetadex.gitbook.io/docs/academy/tutorial-guides",
  MARKETS: "#",
  DEVELOPER_GITHUB: "https://github.com/TetaDex-Lab",
};
