import { fullChains } from "@/constants/wallets";
import useInterface from "@/stores/interface";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useAccount } from "wagmi";

const useActiveChains = () => {
  const { metadata: interfaceConfig, reloadTrigger } = useInterface();
  const account = useAccount();

  const metadataRef = useRef(null); // Ref to store latest valid metadata

  // Track metadata changes and store the latest valid one
  useEffect(() => {
    if (interfaceConfig?.multiChain?.chainList?.length) {
      metadataRef.current = interfaceConfig; // Update ref with valid metadata
    }
  }, [interfaceConfig]);

  // Use the latest metadata from the ref
  const getActiveMetadata = useCallback(() => {
    return interfaceConfig?.multiChain?.chainList?.length ? interfaceConfig : metadataRef.current;
  }, [interfaceConfig]);

  const chains = useMemo(() => {
    const activeMetadata = getActiveMetadata(); // Get the latest metadata
    const metadataChainList = activeMetadata?.multiChain?.chainList;

    return metadataChainList?.filter((j) => fullChains?.find((i) => +i?.id === +j?.chainId));
  }, [getActiveMetadata, interfaceConfig]);

  const unsupported = useMemo(() => account?.chainId && !chains?.find((i) => +i.chainId === +account?.chainId), [account?.chainId, chains, reloadTrigger]);

  const currentActiveChain = useMemo(() => chains?.find((i) => +i?.chainId === +account?.chainId) || chains?.[0], [account?.chainId, chains, reloadTrigger]);

  const currentActiveChainTokens = useMemo(
    () =>
      currentActiveChain?.tokenList?.map((i, index) => {
        return {
          ...i,
          isDefaultToken: index === 0 ? true : false,
        };
      }),
    [currentActiveChain?.tokenList, reloadTrigger],
  );

  return { chains, unsupported, currentActiveChain, currentActiveChainTokens };
};

export default useActiveChains;
