import { getNormalWithdrawableAmountApi } from "@/apis/api/withdraw";
import { useRequest } from "ahooks";

/**
 * Retrieves the normal withdrawable amount for current user.
 * @param {Object} requestParam
 * @param {Object} requestParam.params
 * @param {String} requestParam.params.address User's wallet address.
 * @param {Object} useRequestConfigParam
 * @returns {Object}
 */
export const useGetNormalWithdrawableAmountRequest = (requestParam, useRequestConfigParam) => {
  return useRequest(() => getNormalWithdrawableAmountApi(requestParam).then((res) => res.data), useRequestConfigParam);
};
