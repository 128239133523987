import { useToast } from "@/components/ui/use-toast";
import { CustomEvents } from "@/constants/customEvents";
import axios from "@/services/request";
import realtimePrivate from "@/services/ws.realtimePrivate";
import useGlobal from "@/stores/global";
import useUser from "@/stores/user";
import { useEventListener, usePrevious } from "ahooks";
import { useEffect, useRef } from "react";
import { useAccount, useReconnect, useWalletClient } from "wagmi";

/**
 * Bootstrap 后用户信息的初始化请求，以及监听 private ws 推送的处理更新 store
 */
function UserUpdater() {
  const { toast: Toast } = useToast();
  const { setGlobal } = useGlobal();
  const updatedRef = useRef(false);
  const { address, isDisconnected, status } = useAccount();
  const previousAddress = usePrevious(address);
  const prevStatus = usePrevious(status);
  const walletClient = useWalletClient();

  const { reconnect } = useReconnect();

  const { resetUser, setUser, keys, currentClientAccountId, isAlreadySigned, currentActiveAccount: oldCurrentActiveAccount, account } = useUser();
  const alreadySigned = isAlreadySigned();
  const previousClientAccountId = usePrevious(currentClientAccountId);

  // {apiKey, l2Key}
  const apiKey = keys?.[address?.toLowerCase()]?.[currentClientAccountId]?.apiKey;
  const l2Key = keys?.[address?.toLowerCase()]?.[currentClientAccountId]?.l2Key;

  const accountFields = [
    "account",
    "collateral",
    "position",
    "positionTransaction",
    "deposit",
    "withdraw",
    "transferIn",
    "transferOut",
    "order",
    "orderFillTransaction",
    "positionTermList",
    "tradeHistory",
    "fastWithdraw",
  ];

  useEffect(() => {
    if (prevStatus !== "reconnecting" && isDisconnected && !address) {
      resetUser();
      realtimePrivate.logout();
      updatedRef.current = false;
    } else {
      if (walletClient?.isPending && !address) {
        reconnect();
      }
    }
  }, [prevStatus, isDisconnected, address]);

  useEffect(() => {
    // when user disconnected / swap wallet account -> reset onboardingClientAccountId
    if (status === "reconnecting" && account && account.length < 1 && address !== previousAddress) {
      setGlobal({ onboardingClientAccountId: "" });
    }
  }, [previousAddress, prevStatus, previousClientAccountId, account]);

  useEffect(() => {
    if (address && previousAddress && previousAddress !== address) {
      resetUser();
      realtimePrivate.logout();
      updatedRef.current = false;
    }
  }, [address, previousAddress]);

  const getUserInfo = async () => {
    if (updatedRef.current) return;
    try {
      updatedRef.current = true;
      // 先清空既有字段
      setUser(Object.fromEntries(accountFields.map((k) => [k, []])));
      const [getUserInfoRes, getAccountPageRes] = await Promise.all([axios.get("/v1/private/user/getUserInfo"), axios.get("/v1/private/account/getAccountPage")]);
      // 当前 clientAccountId 对应的 account 数据
      const currentActiveClientIdAccount = getAccountPageRes?.data?.data?.dataList?.find((i) => {
        return currentClientAccountId === i?.clientAccountId;
      });
      if (!currentActiveClientIdAccount) {
        return;
      }

      const currentActiveAccount = {
        ...getUserInfoRes?.data?.data,
        ...currentActiveClientIdAccount,
        accountId: currentActiveClientIdAccount?.id,
        keys: {
          apiKey,
          l2Key,
        },
      };

      setUser({
        account: getAccountPageRes?.data?.data?.dataList,
        currentActiveAccount: currentActiveAccount,
      });
      fetchAccountHistory();
      realtimePrivate?.init(); // 手动连接 ws
    } catch (e) {
      Toast.show(e?.message, { type: "error" });
    }
  };

  // 子账号切换，断开私有 ws 连接，重新连接
  useEffect(() => {
    if (currentClientAccountId && previousClientAccountId && previousClientAccountId !== currentClientAccountId) {
      realtimePrivate.logout();
      updatedRef.current = false;
      alreadySigned && getUserInfo();
    }
  }, [currentClientAccountId, previousClientAccountId, alreadySigned]);

  // 记录唯一标识不是 id 字段的映射
  const getIdField = (k) => ({ position: "contractId", collateral: "coinId", positionTermList: (item) => item.contractId + item.termCount }[k] || "id");

  const fetchAccountHistory = async () => {
    const accountId = useUser.getState()?.currentActiveAccount?.accountId;

    const [positionTransactionRes, orderFillTransactionRes, positionTermRes, tradeHistoryRes] = await Promise.all([
      axios.get("/v1/private/account/getPositionTransactionPage", { params: { accountId, filterCloseOnly: true } }),
      axios.get("/v1/private/order/getHistoryOrderFillTransactionPage", { params: { accountId } }),
      axios.get("/v1/private/account/getPositionTermPage", { params: { accountId } }),
      axios.get("/v1/private/order/getHistoryOrderPage", { params: { accountId, filterStatusList: ["FILLED", "CANCELED"] } }),
    ]);

    const dataToMerge = {
      positionTransaction: positionTransactionRes?.data?.data?.dataList,
      orderFillTransaction: orderFillTransactionRes?.data?.data?.dataList,
      positionTermList: positionTermRes?.data?.data?.dataList,
      tradeHistory: tradeHistoryRes?.data?.data?.dataList.map((r) => ({ ...r, from: "http-api" })),
    };

    setUser({
      ...Object.fromEntries(
        Object.entries(dataToMerge)
          .filter(([k, v]) => ["positionTransaction", "orderFillTransaction", "positionTermList", "tradeHistory"].includes(k) && v?.length > 0)
          .map(([k, v]) => {
            const oldRows = useUser.getState()[k] || [];
            v.forEach((rr) => {
              rr.id = rr.id || (typeof getIdField(k) === "function" ? getIdField(k)(rr) : rr[getIdField(k)]);
            });
            return [k, [...oldRows.filter((r) => !v.find((rr) => rr.id == r.id)), ...v].sort((a, b) => Number(b.createdTime) - Number(a.createdTime))];
          }),
      ),
    });
  };

  useEffect(() => {
    if (address && !alreadySigned) {
      setGlobal({ disclaimerAndSignVisible: true });
    }
  }, [address, alreadySigned]);

  useEffect(() => {
    if (alreadySigned) {
      realtimePrivate.logout();
      updatedRef.current = false;
      getUserInfo();
    }
  }, [alreadySigned]);

  // 监听 ws 推送
  useEventListener(
    CustomEvents["ws-message"],
    (e) => {
      if ((e.detail?.type == "trade-event" || e.detail?.type == "assets-event") && e.detail?.content?.data) {
        // e.detail?.content?.data 形如 {account:[],position:[],...}
        // 特殊处理 tradeHistory，将新推送的终态 order 合并到 tradeHistory
        e.detail.content.data.tradeHistory = e.detail?.content?.data.order?.filter((o) => ["FILLED", "CANCELED"].includes(o.status)) || [];
        setUser({
          ...Object.fromEntries(
            Object.entries(e.detail?.content?.data)
              .filter(([k, v]) => accountFields.includes(k) && v?.length > 0)
              .map(([k, v]) => {
                const oldRows = useUser.getState()[k] || [];
                v.forEach((rr) => {
                  rr.id = rr.id || (typeof getIdField(k) === "function" ? getIdField(k)(rr) : rr[getIdField(k)]);
                });
                return [
                  k,
                  // 标记来自 Snapshot 还是 update
                  [...oldRows.filter((r) => !v.find((rr) => rr.id == r.id)), ...v.map((r) => ({ ...r, from: e.detail?.content.event }))].sort((a, b) => Number(b.createdTime) - Number(a.createdTime)),
                ];
              }),
          ),
          // 更新当前账号信息
          currentActiveAccount: {
            ...oldCurrentActiveAccount,
            ...e.detail?.content?.data?.account?.find((i) => {
              return currentClientAccountId === i?.clientAccountId;
            }),
          },
        });
      }
    },
    { target: document },
  );

  return <></>;
}

export default UserUpdater;
