import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tooltip as UITooltip, TooltipArrow, TooltipContent, TooltipPortal, TooltipTrigger } from "@/components/ui/tooltip";
import { twMerge } from "tailwind-merge";
import ui from "@/constants/ui";

/**
 * 平替之前的 Tooltip 组件，算是简写 ui/tooltip 组件
 */
const Tooltip = ({ placement = "bottom", title = "", className, arrow = true, children, contentProps = {} }) => {
  const [open, setOpen] = useState(false);
  return (
    <UITooltip
      delayDuration={10}
      closeDelayDuration={100}
      open={open}
      onOpenChange={(value) => {
        setOpen(value);
      }}
    >
      <TooltipTrigger
        onMouseOver={() => {
          setOpen(true);
        }}
        asChild
      >
        {children}
      </TooltipTrigger>
      {title && (
        <TooltipPortal>
          <TooltipContent
            onMouseOver={() => {
              setOpen(true);
            }}
            onMouseOut={() => {
              setOpen(false);
            }}
            side={placement}
            className={twMerge("max-w-[300px] text-xs", ui?.tooltipContentClassName, className)}
            {...contentProps}
          >
            {arrow && <TooltipArrow className="fill-tooltip" />}
            {title}
          </TooltipContent>
        </TooltipPortal>
      )}
    </UITooltip>
  );
};

Tooltip.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.any,
  children: PropTypes.any,
  arrow: PropTypes.bool,
  contentProps: PropTypes.object,
  className: PropTypes.string,
};

export default Tooltip;
