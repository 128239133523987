import { WS_TOPIC_FUNDING_RATE, WS_TOPIC_METADATA, WS_TOPIC_TICKER_ALL } from "@/constants/trade";
import useSubscribePublicTopic from "@/hooks/useSubscribePublicTopic";
import useInterface from "@/stores/interface";
import useSymbol from "@/stores/symbol";
import { useThrottleFn } from "ahooks";

/**
 * 订阅 metadata topic，更新全局 interface store
 */
const QuoteDataHandler = () => {
  const { getSymbols, setSymbol, getCurrentSymbol } = useSymbol();
  const { setInterface } = useInterface();
  // const symbols = getSymbols();
  const symbolInfo = getCurrentSymbol();
  useSubscribePublicTopic(
    ({ data }) => {
      if (data && data.length) {
        setInterface({ metadata: data[0] });
      } else {
        setInterface({ metadata: [] });
      }
    },
    { topic: WS_TOPIC_METADATA() },
  );

  const { run: tickerHandler } = useThrottleFn(
    (data) => {
      const newSymbols = {};
      data?.data?.forEach(({ contractId, priceChangePercent, trades, size, value, high, low, lastPrice, indexPrice, oraclePrice, openInterest, ...rest }) => {
        // lastPrice ? Number(lastPrice) : symbolInfo.lastPrice,
        // delta 更新只是部分字段

        const symbolInfo = getSymbols().find((s) => s.contractId == contractId) || {};
        symbolInfo.symbol &&
          (newSymbols[symbolInfo.symbol] = {
            ...symbolInfo,
            contractId,
            symbol: symbolInfo.contractName,
            lastPrice: lastPrice ? Number(lastPrice) : symbolInfo.lastPrice,
            lastPriceChange: Number(lastPrice || 0) - Number(symbolInfo.lastPrice || lastPrice || 0),
            price24hPcnt: priceChangePercent ? Number(priceChangePercent) : symbolInfo.price24hPcnt,
            highPrice24h: high ? Number(high) : symbolInfo.highPrice24h,
            lowPrice24h: low ? Number(low) : symbolInfo.lowPrice24h,
            turnover24h: value ? Number(value) : symbolInfo.turnover24h,
            trades: trades ? Number(trades) : symbolInfo.trades,
            oraclePrice: oraclePrice ? Number(oraclePrice) : symbolInfo.oraclePrice,
            indexPrice: indexPrice ? Number(indexPrice) : symbolInfo.indexPrice,
            volume24h: size ? Number(size) : symbolInfo.volume24h, // volume <-> size
            funding1h: Number(0), // 1H funding 暂无数据
            openInterest: Number(openInterest),
            value: value ? Number(value) : symbolInfo.value,
            ...rest,
          });
      });

      const symbolsObj = useSymbol.getState().symbols;
      setSymbol({ symbols: { ...symbolsObj, ...newSymbols } });
    },
    { wait: 200 },
  );

  const fundingRateHandler = (data) => {
    if (data?.data?.length > 0) {
      setSymbol({
        currentSymbolFundingRate: data.data[0].forecastFundingRate,
      });
    } else {
      setSymbol({
        currentSymbolFundingRate: null,
      });
    }
  };

  useSubscribePublicTopic(tickerHandler, { topic: WS_TOPIC_TICKER_ALL() });

  useSubscribePublicTopic(fundingRateHandler, { topic: WS_TOPIC_FUNDING_RATE(symbolInfo.contractId) });

  // 已订阅 ticker.all topic 无需再订阅 ticker.${contractId} topic
  // useSubscribePublicTopic(tickerHandler, { topic: WS_TOPIC_TICKER(symbolInfo.contractId), group: "ticker" });

  return <></>;
};

export default QuoteDataHandler;
